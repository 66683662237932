import React, { useEffect, useState } from 'react';
import { FormikProvider, Field, Form, Formik, ErrorMessage, useFormik } from 'formik'
import * as yup from "yup";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { getCuisine, addCuisine } from '../../Services/service';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

//"admin", "vendor", "customer", "subAdmin"
const CuisinesAdd = () => {

    const navigate = useNavigate();
    //{ email, phone, country_code, password, confirm_password } 
    const { role } = useParams();
    const [initialValues, setInitialValue] = useState({
        name: "",
    });



    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validationCheck = yup.object().shape({
        name: yup.string().trim().required("Name is required"),
      
    })

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: validationCheck,
        onSubmit: async (values) => {
            try {
                const response = await addCuisine({ role: role, ...values });
                if (response.status === 200) {
                    toast(response.data.message);
                    navigate('/admin/cuisines');
                }
            } catch (error) {
                toast.error(error.response.data.message)
            }
        }
    });

    return (
        <>

            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Add Cuisine</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Add Cuisine</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Detail</h3>

                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                    <div className="form-group">
                                            <label htmlFor="inputName">Name</label>
                                            <Field
                                                type="text"
                                                name="name"
                                                placeholder="Name"
                                                className={`form-control`}

                                            />
                                            <div className="text-danger">
                                                <ErrorMessage name="name" />
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input type="submit" value="Add" className="btn btn-success " />
                            </div>
                        </div>

                    </Form>
                </FormikProvider>
            </section>


        </>)
}
export default CuisinesAdd;