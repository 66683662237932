import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { getAllUsers, changeStatus } from '../../Services/service';
import Pagination from '../../Component/Pagination';
import { TransitEnterexit } from '@mui/icons-material';
import { toast } from 'react-toastify';

import * as constant from "../../constant";


const VendorList = () => {
  // const history = useHistory()
  const [searchParams] = useSearchParams();
  const  role  = 'vendor';
  const [Data, setData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  
  const [List, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [order, setOrder] = useState({
    status: false,
    name: false
  })
  const [status, setStatus] = useState(null);

  async function getList(page = 1) {

    const response = await getAllUsers({ role: role, pageNumber: page, pageSize: 10 , status:status , searchItem:searchItem });
    if (response.status === 200) {
      setList(response.data.data);
      setData(response.data.data)
      setTotalPage(response.data.coundData.totalPages);
      return null;
    }
  }

  useEffect(() => {
    // if (searchParams.get("page") <= totalPage) {
    //   getList(searchParams.get("page"));
    // } else {
    //   getList(1)
    // }


    getList(1)
  }, [status,role, totalPage,searchItem]);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getList(pageNumber);
  };

  const handleStatus = async (index, status) => {
    const confirm = window.confirm("Are you sure wanna change the status");

    if (confirm) {
      console.log(List[index]);
      const updatedList = [...List];
      const toggledStatus = updatedList[index].status === 0 ? 1 : 0;
      updatedList[index] = { ...updatedList[index], status: toggledStatus };
      setList(updatedList);
      try {
        const response = await changeStatus({ id: updatedList[index]._id, status: toggledStatus });
        if (response.status === 200) {
          toast(response.data.message);
        }
      } catch (error) {
        toast(error.response.data.message)
      }
    }
  }


  const handleSort = (type) => {
    setOrder(prev => ({
      ...prev,
      [type]: !prev[type]
    }));

    const isAscending = !order[type];

    switch (type) {
      case "status":
        if (isAscending) {
          let sorted = List.sort((a, b) => a.status - b.status);
          setList(sorted)
        } else {
          let sorted = List.sort((a, b) => b.status - a.status);
          setList(sorted)
        }

        break;
      case "name":
        if (isAscending) {
          let sorted = List.sort((a, b) => a.first_name.localeCompare(b.first_name))
          setList(sorted)
        } else {
          let sorted = List.sort((a, b) => b.first_name.localeCompare(a.first_name))
          setList(sorted)
        }
        break;
      default:
        break;
    }

    console.log(List)
  };



  const handleSearch = (e) => {
    let filtered = [];
    const input = e.target.value.trim().toLowerCase();

    setSearchItem(input);
    // setList(Data)
    // if (input) {
    //   filtered = List.filter((el) => {
    //     return Object.values(el).some((val) =>
    //       String(val).toLowerCase().includes(input)
    //     );
    //   });
    //   setList(filtered)
      
    // }
  }


  const handleFilter = (e) => {
    if(e.target.value=='all'){
      setStatus(null);
    }else{
      setStatus(e.target.value);
    }
    
  }






  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{role} Management</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active">Vendor Management</li>
              </ol>
            </div>
          </div>
        </div>
      </section>


      <section className="content">
        <div className='my-2 d-flex justify-content-between'>
          <div className='row mr-sm-5 mr-1 flex-fill'>
            <div className='col-md-6'> <input className="form-control" type="search" placeholder={`Search ${role} by Name`} onChange={handleSearch} /></div>
            <div className='col-md-6'>   <select onChange={handleFilter} class="form-control">
            <option value="all"> Select Status</option>
            <option value="1"> Active</option>
            <option value="0"> In-Active</option>
            </select></div>
 
          </div>
          <Link className='btn btn-success' to={`./add`}> <i className='fa fa-plus mx-1'></i> Add New {role}</Link>
        </div>
        <div className="card">
          <div className="card-body p-0">
            <table className="table table-striped projects">
              <thead>
                <tr>
                  <th >
                    #
                  </th>
                  <th >
                    Full Name
                    <span style={{ cursor: "pointer" }} onClick={() => handleSort("name")}>
                      <SortIcon w={15} h={15} />
                    </span>
                  </th>
                 
                  {role=='customer' && 
                  <>
                

                  <th >
                    Email
                  </th>

                  <th>
                  Phone
                </th>
                  </>
                  }


                  {role=='vendor' &&   
                  
                  <>
                  <th >
                    Vendor Id
                  </th>

                  <th >
                  Vendor picture
                  </th>

                  <th>Total number of dishes offered by Vendor</th>
                  <th>Total number of orders delivered by Vendor</th>

                  </>


                  }
                  
                  <th className="text-center">
                    Status
                    <span style={{ cursor: "pointer" }} onClick={() => handleSort("status")}>
                      {/* <SortIcon w={15} h={15} /> */}
                    </span>
                  </th>
                  <th >
                  </th>
                </tr>
              </thead>
              <tbody>
                {List?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      #{index + 1}
                    </td>
                    <td>
                      <span>
                        {item.full_name}
                      </span>
                    </td>

                    {role=='customer' &&  
                   <>
                    <td>
                      <span>
                        {item.email}
                      </span>


                    </td>



                    <td>
                      <span>
                        {item.phone}
                      </span>
                    </td>

                    </>
                }



                    {role=='vendor' &&     
                    <>
                    <td>
                      <span>
                        {item.vendor_id}
                      </span>
                    </td>
                     <td>
                     <span>
                       {item.profile_image!=undefined && <img src={constant.base+"/"+item.profile_image} style={{width:70}}/>}
                     
                    
                     </span>
                   </td>
                    <td>
                    <span>
                      0
                    </span>
                  </td>
                   <td>
                   <span>
                    0
                   </span>
                 </td>
                 </>

                  }



                    <td className="project-state">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => handleStatus(index)}>
                        {item.status ?
                          (<span className="badge badge-success" >Active</span>)
                          :
                          (<span className="badge badge-danger">In-active</span>)
                        }
                      </div>
                    </td>
                    <td className="project-actions text-right">
                      { role=='vendor' && 

<>
<Link to={`../add_dish/${item._id}`} className="btn btn-success btn-sm">
                    <i className="fas fa-pencil-alt">
                    </i>
                    Upload a Dish
                    </Link>
                    <Link to={`../dish_list/${item._id}`} className="btn btn-danger btn-sm">
                    <i className="fas fa-pencil-alt">
                    </i>
                    Dish List
                    </Link>     

                    <Link to={`../add_set/${item._id}`} className="btn btn-success btn-sm">
                    <i className="fas fa-pencil-alt">
                    </i>
                    Upload a Set
                    </Link>
                    <Link to={`../set_list/${item._id}`} className="btn btn-danger btn-sm">
                    <i className="fas fa-pencil-alt">
                    </i>
                    Set List
                    </Link>                
</>
         

                    
                        
                      }



                      {role=='vendor'? <Link to={`../vendor_edit/${item._id}`} className="btn btn-info btn-sm">
                        <i className="fas fa-pencil-alt">
                        </i>
                        Edit
                      </Link> : <Link to={`./edit/${item._id}`} className="btn btn-info btn-sm">
                        <i className="fas fa-pencil-alt">
                        </i>
                        Edit
                      </Link>}
                      

                      {/* No View Route Created */}
                      <Link to={`./view/${item._id}`} className="btn btn-info btn-sm">
                        <i className="fas fa-eye">
                        </i>
                        View
                      </Link>

                    </td>
                  </tr >
                ))}
              </tbody >
            </table >
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              onPageChange={handlePageChange}
            />
          </div >

        </div >


      </section >





    </>
  )
}

export default VendorList;




const SortIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.w ? props.w : 20}
    height={props.h ? props.h : 20}
    viewBox="0 0 24 24"
    style={{
      fill: "rgba(0, 0, 0, 1)",
      transform: "",
      msfilter: "",
    }}
    {...props}
  >
    <path d="M8 16H4l6 6V2H8zm6-11v17h2V8h4l-6-6z" />
  </svg>
);

