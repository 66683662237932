import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Layout from "../Layout/layout";
import ForgetPassword from "../Pages/Auth/forgetPassword";
import Login from "../Pages/Auth/login";
import Otp from "../Pages/Auth/otp";
import ResetPassword from "../Pages/Auth/resetPassword";

export default function LoginRoutes() {
    return useRoutes([
        {
            path: 'auth',
            element: <Layout />,
            children: [
                { path: "login", element: <Login /> },
                { path: "resetpassword", element: <ResetPassword /> },
                { path: "forgetpassword", element: <ForgetPassword /> },
                { path: "otp", element: <Otp /> },
                { path: "", element: <Navigate to="/auth/login" replace /> },

            ]
        },
        // { path: "/auth/login", element: <Navigate to='/' replace /> },
        { path: "*", element: <Navigate to='/auth/login' replace /> },

    ])
}