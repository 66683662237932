import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <footer className="main-footer">
                <strong>Copyright &copy; 2014-2021 <a href="#">Eats Admin</a>.</strong>
                All rights reserved.
            </footer>
        </>
    )
}

export default Footer;