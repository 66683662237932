
import React, { useEffect, useState } from 'react'
import { getUser } from '../../Services/service';
import { useParams } from 'react-router-dom';
import * as constant from "../../constant";

function IngredientsView() {
    const { id } = useParams();
    const [user, setUser] = useState({});
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        const api_call = async () => {
            const response = await getUser({ id: id });
            if (response.status === 200) {
                const userData = response.data.data;
                setUser(userData);
                setLoader(false);
            }
        }
        api_call();
    }, []);

    return (
        <div>

    {loader ? (
        <p>loading..</p>
      ) : ( 

        <div className="row">
        <div className="col-md-6 margin-30">
            <div className="card card-primary">
                <div className="card-header">
                    <h3 className="card-title">Detail</h3>

                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                            <i className="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
                







                <div className="card-body">
                    <div className="form-group">
                        <label htmlFor="inputName">Vendor name</label>
                        <div>
                            <NullWrite value={user.first_name} placeholder="First Name" />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputName">Vendor picture</label>
                        <div>
                            {
                                user.profile_image!=undefined && 
                                <img src={constant.base+"/"+user.profile_image} style={{width:100}}/>
                            }
                       
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputName">Vendor ID</label>
                        <div>
                            <NullWrite value={user.vendor_id} />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputName">Vendor average rating</label>
                        <div>
                            <NullWrite value="0" />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputName">Country of origin</label>
                        <div>
                            <p>{user.country ?? "-"}</p>
                        </div>
                    </div>

                    




                        <div className="form-group">
                        <label htmlFor="inputName">Last payment cycle date</label>
                        <div>
                            <NullWrite value="--" />
                        </div>
                        </div>

                        <div className="form-group">
                        <label htmlFor="inputName">Email id</label>
                        <div>
                            <NullWrite value={user.email} />
                        </div>
                        </div>

                        <div className="form-group">
                        <label htmlFor="inputName">Phone number</label>
                        <div>
                            <NullWrite value={user.phone} />
                        </div>
                        </div>

                        <div className="form-group">
                        <label htmlFor="inputName">Cuisines offered</label>
                        <div>
                            <NullWrite value="---" />
                        </div>
                        </div>


                       







                        <div className="form-group">
                        <label htmlFor="inputName">All dishes of Vendor</label>
                        <div>
                            <NullWrite value="0" />
                        </div>
                        </div>

                        <div className="form-group">
                        <label htmlFor="inputName">Total number of dishes</label>
                        <div>
                            <NullWrite value="0" />
                        </div>
                        </div>

                        <div className="form-group">
                        <label htmlFor="inputName">Total number of active dishes</label>
                        <div>
                            <NullWrite value="0" />
                        </div>
                        </div>

                        <div className="form-group">
                        <label htmlFor="inputName">Total number of dishes delivered by Vendor</label>
                        <div>
                            <NullWrite value="0" />
                        </div>
                        </div>

                        <div className="form-group">
                        <label htmlFor="inputName">Total number of orders delivered by Vendor</label>
                        <div>
                            <NullWrite value="0" />
                        </div>
                        </div>

                        


                    

                </div>
            </div>
        </div>

    </div>
      )}

          
        </div>
    )
}

export default IngredientsView;


const NullWrite = ({ value, placeholder }) => {
    return !value ? (
        <span className='text-danger'>
            {placeholder} not defined
        </span>
    ) : value;
}