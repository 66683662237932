import React, { useEffect, useState } from 'react';
import { FormikProvider, Field, Form, Formik, ErrorMessage, useFormik } from 'formik'
import * as yup from "yup";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { getUser, updateUser } from '../../Services/service';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';


const UserEdit = () => { 
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialValues, setInitialValue] = useState({
    first_name: "",
    phone: "",
    email: "",
    country_code: "",
    status: 0,
  });



  useEffect(() => {

    const api_call = async () => {
      const response = await getUser({ id: id });
      if (response.status === 200) {
        const { first_name, last_name, status } = response.data.data;
        setInitialValue({ first_name, last_name, status });
      }
    }

    api_call();

  }, []);



  const validationCheck = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    status: yup.string().required("Status is required")
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationCheck,
    onSubmit: async (values) => {
      try {
        values.id = id;
        const response = await updateUser(values);
        if (response.status === 200) {
          navigate('/admin/user/customer');
          toast(response.data.message);
        }
      } catch (error) {
        toast(error.response.data.message)
      }
    }
  });



  return (
    <>

      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Edit User</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active">Edit User</li>
              </ol>
            </div>
          </div>
        </div>
      </section>


      <section className="content">
        <FormikProvider value={formik} >
          <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Detail</h3>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                        <i className="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="inputName">User Name</label>
                      <Field
                        type="first_name"
                        name="first_name"
                        placeholder="User Name"
                        className={`form-control`}
                      />


                      <div className="text-danger">
                        <ErrorMessage name="first_name" />
                      </div>
                    </div>

             


                    <div className="form-group">
                      <label htmlFor="inputName">Status</label>
                      <Field as="select" name="status" className="form-control">
                        <option value="1">Active</option>
                        <option value="0">In-Active</option>
                      </Field>

                      <div className="text-danger">
                        <ErrorMessage name="status" />
                      </div>
                    </div>



                  </div>
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-12">
                <input type="submit" value="Edit User" className="btn btn-success " />
              </div>
            </div>

          </Form>
        </FormikProvider>
      </section>


    </>
  )
}

export default UserEdit;