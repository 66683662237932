import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllBanner, getBanner, deleteBanner, updateBanner } from '../../Services/service';
import parse from 'html-react-parser';
import { base } from '../../constant';

const BannerList = () => {

    const [List, setList] = useState([]);

    useEffect(() => {

        async function getList() {
            const response = await getAllBanner();
            if (response.status === 200) {
                setList(response.data.data);
                return null;
            }
        }

        getList()

    }, []);


    const deleteHandler = async (id) => {
        var result = window.confirm("Want to delete?");
        if (result) {
            const response = await deleteBanner({ id: id });
            if (response.status === 200) {
                setList(List.filter(record => record._id !== id));
            }
        }
    }


    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Banner Managment</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Banner Managment</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">


                <div className="card-header">
                    <div className="card-tools">
                        <Link to='./add' className="btn btn-info btn-sm float-right" >
                            Add Banner
                        </Link>
                    </div>
                </div>


                <div className="card">

                    <div className="card-body p-0">
                        <table className="table table-striped projects">
                            <thead>
                                <tr>
                                    <th >
                                        #
                                    </th>
                                    <th style={{ width: "20%" }}>
                                        Image
                                    </th>
                                    <th style={{ width: "20%" }}>
                                        Name
                                    </th>

                                    <th >
                                    </th>
                                </tr>
                            </thead>
                            <tbody>



                                {List.length !== 0 ? List.map((item, index) => {

                                    return <tr key={index + 1}>
                                        <td>
                                            #{index + 1}
                                        </td>
                                        <td>
                                            <a>
                                                <img src={base + "/" + item.image} style={{ width: 100 }} />
                                            </a>

                                        </td>
                                        <td>
                                            <a>
                                                {item.banner}
                                            </a>

                                        </td>

                                        <td className="project-actions text-right">

                                            <Link to={`./edit/${item._id}`} className="btn btn-info btn-sm" >
                                                <i className="fas fa-pencil-alt">
                                                </i>
                                                Edit
                                            </Link>
                                            <a onClick={() => {
                                                deleteHandler(item._id)
                                            }} className="btn btn-danger btn-sm" >
                                                <i className="fas fa-trash">
                                                </i>
                                                Delete
                                            </a>
                                        </td>
                                    </tr>
                                }) : <h4 className='text-center'>No Banner Found</h4>}

                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

        </>
    )
}

export default BannerList;