import React, { useEffect, useState } from 'react';
import { FormikProvider, Field, Form, Formik, ErrorMessage, useFormik } from 'formik'
import * as yup from "yup";
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import * as constant from "../../constant";


const VendorTutorialAdd = () => {

    const navigate = useNavigate();
    //{ email, phone, country_code, password, confirm_password } 
    const { role } = useParams();
    const [initialValues, setInitialValue] = useState({
        image: '',
        title: "",
        content: "",
    });



    const validationCheck = yup.object().shape({
        title: yup.string().trim().max(255, 'Maximum 255 character allowed').required("Title is required"),
        image: yup
        .mixed()
        .required("Image is required (supported type .png,.jpg,.jpeg ) "),
        content:  yup.string().trim().required("Description is required"),
    })

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: validationCheck,
        onSubmit: async (data) => {

          const form_data = new FormData();
          form_data.append("tutorial_image", data.image);
          form_data.append("title", data.title);
          form_data.append("content", data.content);
          form_data.append("tutorial_type", 'vendor');
        

          try {
        
            let token = localStorage.getItem("token");
            const headers = { Authorization: "Bearer " + token };
            const response = await axios.post(constant.dashBoardUrl + "/addTutorial", form_data, { headers });
    
    
            if (response.data.status) {
              
              toast.success("Tutorial added successfully!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/admin/vendor-tutorial");
    
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } catch (error) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }


        }
    });



    const {
      values,
      errors,
      touched,
      isSubmitting,
      setSubmitting,
      handleChange,
      handleSubmit,
      setValues,
      getFieldProps,
      setFieldValue,
      setFieldError,
      setErrors,
    } = formik;


    return (
        <>

            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Add Tutorial</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Add Tutorial</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Detail</h3>

                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>

                                  
                                    <div className="card-body">
                                    <div className="form-group">
                                            <label htmlFor="inputName">Image</label><br/>
                                        

                                        <input
                                                    type="file"
                                                    id="fileInput"
                                                    accept="image/*"
                                                  onChange={(event) => {
                                                const file = event.target.files[0];


                                                if (
                                                    file.type == "image/png" ||
                                                    file.type == "image/jpg" ||
                                                    file.type == "image/jpeg" 
                                                  ) {
                                                    setFieldValue('image', file);
                                                  } else {
                                                    setFieldValue('image', "");
                                                  }

                                              }}
                                           />


                                            <div className="text-danger">
                                                <ErrorMessage name="image" />
                                            </div>
                                    </div>

                                    <div className="form-group">
                                            <label htmlFor="inputName">Title</label>
                                            <Field
                                                type="text"
                                                name="title"
                                                placeholder="Title"
                                                className={`form-control`}

                                            />
                                            <div className="text-danger">
                                                <ErrorMessage name="title" />
                                            </div>
                                    </div>

                                    <div className="form-group">
                                            <label htmlFor="inputName">Content</label>
                                         


                                          <Field
                                            as="textarea"
                                            id="content"
                                            name="content"
                                            className={`form-control content_box`}
                                            placeholder="Enter a description"
                                            onChange={handleChange}
                                            value={values.content}
                                            />

                                            <div className="text-danger">
                                                <ErrorMessage name="content" />
                                            </div>
                                    </div>

 
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input type="submit" value="Add Tutorial" className="btn btn-success " />
                            </div>
                        </div>

                    </Form>
                </FormikProvider>
            </section>


        </>)
}
export default VendorTutorialAdd;