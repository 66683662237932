import { FormikProvider, Field, Form, Formik, ErrorMessage, useFormik } from 'formik'
import * as yup from "yup";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { addBanner } from '../../Services/service';
import { toast } from 'react-toastify';

const AddBanner = () => {
    const formData = new FormData();
    const initialValues = {
        banner: '',
        image: ''
    }


    const validationCheck = yup.object().shape({
        banner: yup.string().trim().required("Name is required"),
        image: yup.string().trim().required("Image is required"),
    })

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: validationCheck,
        onSubmit: async (values) => {
            try {
                const response = await addBanner(values);
                if (response.status === 200) {
                    toast(response.data.message);
                }
            } catch (error) {
                toast(error.response.data.message)
            }
        }
    });


    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Add Banner</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Add Banner</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">



                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Detail</h3>

                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="inputName">Name</label>
                                            <Field
                                                type="name"
                                                name="banner"
                                                placeholder="Banner"
                                                className={`form-control`}
                                            />


                                            <div className="text-danger">
                                                <ErrorMessage name="name" />
                                            </div>
                                        </div>


                                        <div className="form-group">
                                            <label htmlFor="inputName">Image</label>
                                            <input id="file" name="image" type="file" onChange={(event) => {
                                                formik.setFieldValue("image", event.currentTarget.files[0]);
                                                formData.append("image", event.currentTarget.files[0]);
                                            }} />


                                            <div className="text-danger">
                                                <ErrorMessage name="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input type="submit" value="Create Testimonial" className="btn btn-success " />
                            </div>
                        </div>

                    </Form>
                </FormikProvider>
            </section>

        </>
    )
}

export default AddBanner;