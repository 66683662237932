import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { resetPassword } from "../../Services/service";
import { toast } from "react-toastify";
import { useLocation, useNavigate, Link } from "react-router-dom";
import * as yup from "yup";
const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const validationSchema = yup.object().shape({
    newPassword: yup
      .string()
      .max(50, "Too Long!!")
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,50}$/,
        "Password must contain at least 8 characters including minimum 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Password must match")
      .required("Confirm Password is required"),
  });
  const onSubmit = async (values) => {
    try {
      const body = {
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
        email: location.state,
      };
      const response = await resetPassword(body);
      if (response.status === 200) {
        // localStorage.setItem("token", response.data.data.token)
        toast(response.data.message);
        navigate("/auth/login");
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  let { submitCount, errors, touched, setFieldValue, handleSubmit, values } =
    Formik;

  return (
    <>
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <Link to={"/"} className="h1">
                <b>Admin</b>Eats
              </Link>
            </div>
            <div className="card-body">
              <p className="login-box-msg">
                You are only one step a way from your new password, recover your
                password now.
              </p>
              <Formik
                initialValues={{
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                <Form>
                  <div className="input-group">
                    <Field
                      type="password"
                      name="newPassword"
                      placeholder="New Password"
                      className={`form-control ${submitCount > 0
                        ? errors?.newPassword && touched?.newPassword
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                        }`}
                    />

                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>

                  <div className="text-danger">
                    <ErrorMessage name="newPassword" />
                  </div>

                  <div className="input-group">
                    <Field
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      className={`form-control ${submitCount > 0
                        ? errors?.confirmPassword && touched?.confirmPassword
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                        }`}
                    />

                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>

                  <div className="text-danger">
                    <ErrorMessage name="confirmPassword" />
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <button type="submit" className="btn btn-primary btn-block">
                        Change password
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>

              <p className="mt-3 mb-1">
                <Link to="/auth/login">Login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
