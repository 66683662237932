import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { getAllUsers, changeStatus, getAllCoupon, deleteCoupon } from '../../Services/service';
import Pagination from '../../Component/Pagination';
import { TransitEnterexit } from '@mui/icons-material';
import { toast } from 'react-toastify';
import moment from "moment";
const UserList = () => {
    // const history = useHistory()
    const [searchParams] = useSearchParams();
    const [Data, setData] = useState([]);
    const [List, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    async function getList(page = 1) {
        const response = await getAllCoupon({ pageNumber: page, pageSize: 10 });

        if (response.status === 200) {
            setList(response.data.data);
            setData(response.data.data)
            setTotalPage(response.data.coundData.totalPages);
            return null;
        }
    }

    useEffect(() => {
       
        // if (searchParams.get("page") <= totalPage) {
        //   getList(searchParams.get("page"));
        // } else {
        //   getList(1)
        // }


        getList(1)
    }, [totalPage]);


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        getList(pageNumber);
    };

    const dateFormat = (dateString) => {
        const dateObject = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-US', options);
        return formattedDate
    }

    const deleteHandler = async (id) => {
        var result = window.confirm("Want to delete?");
        if (result) {
            const response = await deleteCoupon({ id: id });
            if (response.status === 200) {
                setList(List.filter(record => record._id !== id));

            
                if(List.filter(record => record._id !== id).length==0){
                    setTotalPage(0);
                }
            }
        }
    }





    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Coupon Management</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Coupon Management</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">
                <div className='my-2 d-flex justify-content-end'>

                    <Link className='btn btn-success' to={`./add`}> <i className='fa fa-plus mx-1'></i> Add New Coupon</Link>
                </div>
                <div className="card">
                    <div className="card-body p-0">
                        <table className="table table-striped projects">
                            <thead>
                                <tr>
                                    <th >
                                        #
                                    </th>
                                    <th >
                                        Coupon Name
                                    </th>
                                    <th >
                                        Coupon Code
                                    </th>
                                    <th >
                                        Start Date
                                    </th>
                                    <th >
                                        End Date
                                    </th>

                                    <th >
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                           

                                {List && List.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            #{index + 1}
                                        </td>
                                        <td>
                                            <span>
                                                {item.name}
                                            </span>


                                        </td>

                                        <td>
                                            <span>
                                                {item.coupon_code}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                { moment(item.start_date).format('DD MMM YYYY')}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                            { moment(item.end_date).format('DD MMM YYYY')}
                                            </span>
                                        </td>
                                        <td className="project-actions text-right">

                                            <Link to={`./edit/${item._id}`} className="btn btn-info btn-sm">
                                                <i className="fas fa-pencil-alt">
                                                </i>
                                                Edit
                                            </Link>

                                            <Link to={`./view/${item._id}`} className="btn btn-info btn-sm">
                                                <i className="fas fa-eye">
                                                </i>
                                                View
                                            </Link>

                                            <a onClick={() => {
                                                deleteHandler(item._id)
                                            }} className="btn btn-danger btn-sm" >
                                                <i className="fas fa-trash">
                                                </i>
                                                Delete
                                            </a>

                                        </td>
                                    </tr >
                                ))}
                            </tbody >
                        </table >


                        {List.length == 0 ? <h3 className='text-center py-5'>NO COUPON EXISTS</h3> : ""}
                        {totalPage ? <Pagination
                            currentPage={currentPage}
                            totalPages={totalPage}
                            onPageChange={handlePageChange}
                        /> : ""}
                    </div >

                </div >


            </section >





        </>
    )
}

export default UserList;




const SortIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.w ? props.w : 20}
        height={props.h ? props.h : 20}
        viewBox="0 0 24 24"
        style={{
            fill: "rgba(0, 0, 0, 1)",
            transform: "",
            msfilter: "",
        }}
        {...props}
    >
        <path d="M8 16H4l6 6V2H8zm6-11v17h2V8h4l-6-6z" />
    </svg>
);

