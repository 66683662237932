import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import * as constant from "../../constant";
import Select from "react-select";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ToastContainer, toast } from "react-toastify";
import { Routes, Route, useNavigate,useParams } from "react-router-dom";


function AddSet() {
  const { id } = useParams();
  const [setImage, setDishImageURL] = useState("/images/dish-img.png");
  const [communitiesList, setCommunitiesList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectWindowType, setWindowType] = useState("any_time");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = localStorage.getItem("token");
        const headers = { Authorization: "Bearer " + token };
        const response = await axios.get(constant.dashBoardUrl + "/getAllCuisine", { headers });
        const communities = await axios.get(constant.dashBoardUrl + "/getAllCommunities", { headers });
        setCommunitiesList(communities.data.data);
        let couisin_data = response.data.data.map((item, index) => {
          item.label = item.name;
          item.value = item._id;
          return item;
        });
        setOptions(couisin_data);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };
    fetchData();
  }, []);

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, " Set Name must be at least 3 characters ")
      .max(55, " Set Name must be less than 55 characters ")
      .required("Set Name is  required")
      .matches(/^[A-Za-z ]*$/, "Set name is not valid"),
    set_from_time: Yup.boolean(),
    set_to_time: Yup.boolean(),
    set_week_day: Yup.boolean(),
    ingredients_price: Yup.boolean(),
    ready_to_cook_price: Yup.boolean(),
    ready_to_heat_price: Yup.boolean(),
    ready_to_eat_price: Yup.boolean(),

    from_time: Yup.string().when("set_from_time", {
      is: true,
      then: (schema) => schema.required("Time is required"),
      otherwise: (schema) => schema.min(0),
    }),
    to_time: Yup.string().when("set_to_time", {
      is: true,
      then: (schema) => schema.required("Time is required"),
      otherwise: (schema) => schema.min(0),
    }),

    week_day: Yup.array().when("set_week_day", {
      is: true,
      then: (schema) => schema.required("Week Day is required"),
      otherwise: (schema) => schema.min(0),
    }),

    groups: Yup.array().of(
      Yup.object().shape({
        group_name: Yup.string()
          .required("Group Name required")
          .min(3, "Group Name must be at least 3 characters ")
          .max(55, "Group Name must be less than 55 characters ")
          .matches(/^[A-Za-z ]*$/, "Group name is not valid"),
        item_number: Yup.string()
          // .strict(true).trim("Extra spaces should be truncated")
          .required("Item Number is required ")
          .matches(/^\d+$/, "Enter valid Item Number")

          .test("maxTwoDigits", "Item Number should not be more than 500", (number) => Number(number) <= 500),
        communities: Yup.array()
          .of(Yup.string().required("Communities is required"))
          .required("Must have Communities")
          .min(1, "Minimum of 1 Communities"),
      })
    ),
    Ingredients: Yup.string().when("ingredients_price", {
      is: true, // alternatively: (val) => val == true
      then: (schema) =>
        schema
          .required("Ingredients  price is required")
          .matches(/^\d+$/, "Enter valid Price")
          .test("maxTwoDigits", "price should not be more than $500", (number) => Number(number) <= 500),
      otherwise: (schema) => schema.min(0),
    }),
    "Ready to Cook": Yup.string().when("ready_to_cook_price", {
      is: true, // alternatively: (val) => val == true
      then: (schema) =>
        schema
          .required("Ready to Cook  price is required")
          .matches(/^\d+$/, "Enter valid Price")
          .test("maxTwoDigits", "price should not be more than $500", (number) => Number(number) <= 500),
      otherwise: (schema) => schema.min(0),
    }),
    "Ready to Heat": Yup.string().when("ready_to_heat_price", {
      is: true, // alternatively: (val) => val == true
      then: (schema) =>
        schema
          .required("Ready to Heat  price is required")
          .matches(/^\d+$/, "Enter valid Price")
          .test("maxTwoDigits", "price should not be more than $500", (number) => Number(number) <= 500),
      otherwise: (schema) => schema.min(0),
    }),
    "Ready to Eat": Yup.string().when("ready_to_eat_price", {
      is: true, // alternatively: (val) => val == true
      then: (schema) =>
        schema
          .required("Ready to Eat  price is required")
          .matches(/^\d+$/, "Enter valid Price")
          .test("maxTwoDigits", "price should not be more than $500", (number) => Number(number) <= 500),
      otherwise: (schema) => schema.min(0),
    }),
    food_class: Yup.array().min(1, "Class of food is required"),
    cuisine: Yup.array().min(1, "Please add cuisine"),
    set_image: Yup.mixed().required("Set Image is required"),
    description: Yup.string()
      .required("Description is required")
      .min(5, "Description must be at least 5 characters ")
      .max(500, "Description must be less than 500 characters "),
   
  
  });

  return (
    <div class="dash-side-R-body">
      <div class="row">
        <div class="col-md-8 m-auto">
          <div class="form-box signup-form col-md-8 m-auto">
            <div class="row">
              <Formik
                initialValues={{
                  name: "",
                  cuisine: [],
                  set_image: "",
                  food_class: [],
                  description: "",
                  from_time: "",
                  set_from_time: false,
                  set_to_time: false,
                  to_time: "",
                  groups: [
                    {
                      group_name: "",
                      communities: [""],
                      item_number: "",
                    },
                  ],
                  food_class: [],
                  recurring: true,
                  window_type: [],
                  week_day: [],
                  set_week_day: false,
                  Ingredients: "",
                  "Ready to Cook": "",
                  "Ready to Heat": "",
                  "Ready to Eat": "",
                  ingredients_price: false,
                  ready_to_cook_price: false,
                  ready_to_heat_price: false,
                  ready_to_eat_price: false,
                }}
                validationSchema={SignupSchema}
                onSubmit={async (values) => {
                  let formData = new FormData();
                  formData.append("vendor_id", id);
                  formData.append("name", values.name);
                  formData.append("description", values.description);
                  formData.append("cuisine", JSON.stringify(values.cuisine));
                  formData.append("recurring", values.recurring);
                  formData.append("set_image", values.set_image);

                  formData.append("set_contents", JSON.stringify(values.groups));
                  formData.append("food_class", JSON.stringify(values.food_class));

                  try {
                    let token = localStorage.getItem("token");
                    const headers = { Authorization: "Bearer " + token };
                    const response = await axios.post(constant.dashBoardUrl + "/addSet", formData, { headers });
                    toast.success("Set added successfully!", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                    navigate("/admin/user/vendor");
                  } catch (error) {
                    toast.error(error.response.data.message, {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  }
                }}>
                {({ errors, touched, handleChange, handleBlur, values, isSubmitting, setFieldValue, setFieldError }) => (
                  <Form>
                    <ToastContainer />

                  
                    <div class="col-md-12">
                      <div class="upload-photo">
                        <div class="avatar-upload">
                          <div class="avatar-edit">
                            <input
                              id="imageUpload"
                              type="file"
                              name="set_image"
                              accept=".png, .jpg, .jpeg"
                              onChange={(event) => {
                                if (
                                  event.target.files[0].type == "image/png" ||
                                  event.target.files[0].type == "image/jpg" ||
                                  event.target.files[0].type == "image/jpeg"
                                ) {
                                  if (event.target.files[0].size <= 1 * 1024 * 1024) {
                                    setFieldValue("set_image", event.currentTarget.files[0]);
                                    let image_url = URL.createObjectURL(event.currentTarget.files[0]);
                                    setDishImageURL(image_url);
                                  } else {
                                    toast.error("Size must be less than 1 MB", {
                                      position: toast.POSITION.TOP_RIGHT,
                                    });
                                    setFieldError("set_image", "Size must be less than 1 MB");
                                  }
                                } else {
                                  toast.error("Invalid File Format", {
                                    position: toast.POSITION.TOP_RIGHT,
                                  });
                                  setFieldError("set_image", "Invalid File Format");
                                }
                              }}
                            />
                            <label for="imageUpload" class="img-upload-icon">
                              <i class="ri-edit-box-line"></i>
                            </label>
                          </div>
                          <div class="avatar-preview">
                            <div id="imagePreview" class="avatar-preview-img">
                              <img src={setImage && setImage} />
                            </div>
                          </div>
                        </div>
                        <span class="validation-error" style={{ display: "flex" }}>
                          {errors.set_image && touched.set_image && errors.set_image}
                        </span>
                      </div>
                    </div>

                    <div class="form-group mb-4 col-md-12">
                      <label class="form-label">Set Name</label>
                      <input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name.replace(/\s\s+/g, " ")}
                        placeholder="Enter Set name"
                        class="form-control"
                      />
                      <span class="validation-error">{errors.name && touched.name && errors.name}</span>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group mb-4 d-flex justify-content-between">
                        <label class="form-label">Available As Recurring?</label>
                        <label class="switch-custom md-swich">
                          <input type="checkbox" name="recurring" onChange={handleChange} onBlur={handleBlur} value={values.recurring} />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group mb-4 d-flex justify-content-between">
                        <label class="form-label">Enabled/Disabled</label>
                        <label class="switch-custom md-swich">
                          <input
                            type="checkbox"
                            name="status"
                            defaultChecked={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.status}
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group mb-4 col-md-12">
                      <label class="form-label">Cuisine</label>
                      <Select
                        defaultValue={selectedOption}
                        isMulti
                        onChange={(value) => {
                          setFieldValue(
                            "cuisine",
                            value.map((item) => {
                              return item.value;
                            })
                          );
                        }}
                        options={options}
                      />

                      <span class="validation-error">{errors.cuisine && touched.cuisine && errors.cuisine}</span>
                    </div>
                    <div class="form-group mb-4 col-md-12">
                      <label class="form-label">Class Of Food</label>
                      <ul class="box-check-box-list d-flex">
                        <li class="me-4">
                          <label class="coustom-checkbox box-check-box">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                let { value, checked } = e.target;
                                if (checked) {
                                  setFieldValue("ingredients_price", true);
                                  setFieldValue("food_class", [
                                    ...values.food_class,
                                    {
                                      class: "Ingredients",
                                      price: "",
                                    },
                                  ]);
                                } else {
                                  setFieldValue("ingredients_price", false);
                                  setFieldValue(
                                    "food_class",
                                    values.food_class.filter((e) => e.class !== "Ingredients")
                                  );
                                }
                              }}
                            />
                            <span class="checkmark"></span>
                            <div class="content-check">
                              <figure>
                                <img src="/images/food-icon-1.png" />
                              </figure>
                              <h4>Ingredients</h4>
                            </div>
                          </label>
                        </li>
                        <li class="me-4">
                          <label class="coustom-checkbox box-check-box">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                let { value, checked } = e.target;
                                if (checked) {
                                  setFieldValue("ready_to_cook_price", true);
                                  setFieldValue("food_class", [
                                    ...values.food_class,
                                    {
                                      class: "Ready to Cook",
                                      price: "",
                                    },
                                  ]);
                                } else {
                                  setFieldValue("ready_to_cook_price", false);
                                  setFieldValue(
                                    "food_class",
                                    values.food_class.filter((e) => e.class !== "Ready to Cook")
                                  );
                                }
                              }}
                            />
                            <span class="checkmark"></span>
                            <div class="content-check">
                              <figure>
                                <img src="/images/food-icon-2.png" />
                              </figure>
                              <h4>Ready to Cook</h4>
                            </div>
                          </label>
                        </li>
                        <li class="me-4">
                          <label class="coustom-checkbox box-check-box">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                let { value, checked } = e.target;
                                if (checked) {
                                  setFieldValue("ready_to_heat_price", true);
                                  setFieldValue("food_class", [
                                    ...values.food_class,
                                    {
                                      class: "Ready to Heat",
                                      price: "",
                                    },
                                  ]);
                                } else {
                                  setFieldValue("ready_to_heat_price", false);
                                  setFieldValue(
                                    "food_class",
                                    values.food_class.filter((e) => e.class !== "Ready to Heat")
                                  );
                                }
                              }}
                            />
                            <span class="checkmark"></span>
                            <div class="content-check">
                              <figure>
                                <img src="/images/food-icon-3.png" />
                              </figure>
                              <h4>Ready to Heat</h4>
                            </div>
                          </label>
                        </li>
                        <li>
                          <label class="coustom-checkbox box-check-box">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                let { value, checked } = e.target;
                                if (checked) {
                                  setFieldValue("ready_to_eat_price", true);
                                  setFieldValue("food_class", [
                                    ...values.food_class,
                                    {
                                      class: "Ready to Eat",
                                      price: "",
                                    },
                                  ]);
                                } else {
                                  setFieldValue("ready_to_eat_price", false);
                                  setFieldValue(
                                    "food_class",
                                    values.food_class.filter((e) => e.class !== "Ready to Eat")
                                  );
                                }
                              }}
                            />
                            <span class="checkmark"></span>
                            <div class="content-check">
                              <figure>
                                <img src="/images/food-icon-4.png" />
                              </figure>
                              <h4>Ready to Eat</h4>
                            </div>
                          </label>
                        </li>
                      </ul>

                      <span class="validation-error">{errors.food_class && touched.food_class && errors.food_class}</span>
                    </div>
                    <div class="form-group mb-4 col-md-12">
                      <label class="form-label">Price</label>
                      <p class="mb-3">
                        You earn $x from each dish sold! Eats collects $y (z%) to cover marketing, operations, customer support and software
                        development costs."
                      </p>
                      <div class="row row-cols-2">
                        {values.food_class.map((item, index) => {
                          return (
                            <div class="cols" key={index}>
                              <input
                                type="text"
                                name={item.class}
                                placeholder={`Enter Price for ${item.class}`}
                                class="form-control price_box"
                                value={item.price.replace(/\s\s+/g, " ")}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue(`food_class.${index}.price`, e.target.value);
                                  setFieldValue(`${item.class}`, e.target.value);
                                }}
                              />
                             <span class="validation-error">{errors[item.class]}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div class="col-md-12">
                      <hr class="divider-accordion" />
                    </div>
                    <div class="form-group mb-4 col-md-12">
                      <label class="form-label">Description</label>
                      <textarea
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description.replace(/ +/g, " ")}
                        placeholder="Enter Description"
                        class="form-control note"
                      />
                      <span class="validation-error">{errors.description && touched.description && errors.description}</span>
                    </div>
                    <h4 class="inner-title mb-3">Set Contents</h4>
                    <FieldArray
                      name="groups"
                      render={(groupHelpers) => (
                        <div>
                          {values.groups.map((groups, key) => (
                            <div class="form-group mb-4 col-md-12 bg-grey-box">
                              <div class="row">
                                <div class="add-more d-flex align-items-center mt-3">
                                  <label>Group {key + 1}</label>
                                </div>

                                <div class="form-group mb-4 col-md-12">
                                  <label class="form-label">Group Name</label>
                                  <Field
                                    type="text"
                                    name={`groups.${key}.group_name`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.groups[key].group_name.replace(/ +/g, " ")}
                                    placeholder="Enter Group Name"
                                    class="form-control"
                                  />
                                  <span class="validation-error">
                                    <ErrorMessage name={`groups.${key}.group_name`} />
                                  </span>
                                </div>
                                <label class="form-label">Set Communities Name</label>
                                <FieldArray
                                  name={`groups.${key}.communities`}
                                  render={(arrayHelpers) => (
                                    <div>
                                      {values.groups[key].communities.map((communities, index) => (
                                        <div key={index}>
                                          <div class="input-add-box d-flex justify-content-between align-items-center" key={index}>
                                            <Field
                                              as="select"
                                              name={`groups.${key}.communities.${index}`}
                                              placeholder="Enter Communities"
                                              class="form-control">
                                              <option value="">Select Communities</option>
                                              {communitiesList.map((com) => {
                                                return <option value={com._id}>{com.name}</option>;
                                              })}
                                            </Field>
                                            {index !== 0 && (
                                              <a class="action-box delete-action ms-3" onClick={() => arrayHelpers.remove(index)}>
                                                <i class="ri-delete-bin-line"></i>
                                              </a>
                                            )}
                                          </div>
                                          <div>
                                            <span class="validation-error">
                                              <ErrorMessage name={`groups.${key}.communities.${index}`} />
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                      <div class="add-more d-flex align-items-center mt-3">
                                        <a class="action-box  me-2" onClick={() => arrayHelpers.push("")}>
                                          <i class="ri-add-fill"></i>
                                        </a>
                                        Add more
                                      </div>
                                    </div>
                                  )}
                                />
                                <div class="form-group mb-4 col-md-12">
                                  <label class="form-label">Number Of Items Needed To Chosen</label>

                                  <Field
                                    name={`groups.${key}.item_number`}
                                    placeholder="Enter Number Of Items Needed To Chosen"
                                    class="form-control"
                                  />

                                  <span class="validation-error">
                                    <ErrorMessage name={`groups.${key}.item_number`} />
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}

                          <div class="add-more d-flex align-items-center mt-3">
                            <a
                              class="action-box  me-2"
                              onClick={() =>
                                groupHelpers.push({
                                  group_name: "",
                                  communities: [""],
                                  item_number: "",
                                })
                              }>
                              <i class="ri-add-fill"></i>
                            </a>
                            Add more
                          </div>
                        </div>
                      )}
                    />

                   
                
                    <div class="col-md-12">
                    
                  
                      <div class="btn-group-box mt-5 d-flex justify-content-center">
                        <button type="submit" disabled={isSubmitting} class="btn btn-primary">
                          {" "}
                          Add
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSet;
