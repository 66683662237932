
import React, { useEffect, useState } from 'react'
import { getTutorial } from '../../Services/service';
import { useParams } from 'react-router-dom';
import * as constant from "../../constant";

function AppTutorialView() {
    const { id } = useParams();
    const [tutorial, setTutorial] = useState({});
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        const api_call = async () => {
            const response = await getTutorial({ id: id });
            if (response.status === 200) {
                const tutorialData = response.data.data;
                setTutorial(tutorialData);
                setLoader(false);
            }
        }
        api_call();
    }, []);

    return (
        <div>

    {loader ? (
        <p>loading..</p>
      ) : ( 

        <div className="row">
        <div className="col-md-6 margin-30">
            <div className="card card-primary">
                <div className="card-header">
                    <h3 className="card-title">Detail</h3>

                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                            <i className="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
                


                <div className="card-body">

                <div className="form-group">
                        <label htmlFor="inputName">Image</label>
                        <div>
                        <img src={constant.base+"/"+tutorial.tutorial_image} style={{width:100}}/>
                        </div>
                    </div>


                    <div className="form-group">
                        <label htmlFor="inputName">Title</label>
                        <div>
                            <NullWrite value={tutorial.title} placeholder="title" />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputName">Content</label>
                        <div>
                            <NullWrite value={tutorial.content} placeholder="title" />
                        </div>
                    </div>


                </div>
            </div>
        </div>

    </div>
      )}

          
        </div>
    )
}

export default AppTutorialView;


const NullWrite = ({ value, placeholder }) => {
    return !value ? (
        <span className='text-danger'>
            {placeholder} not defined
        </span>
    ) : value;
}