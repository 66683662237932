import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import * as yup from "yup";
import { forgetPassword } from '../../Services/service';


const ForgetPassword = () => {
  const navigate = useNavigate()
  const validationCheck = yup.object().shape({
    email: yup.string().required("Email is required"),
  })
  const onSubmit = async (values) => {

    try {
      const res = await forgetPassword(values);
      if (res.status === 200) {
        console.log(res)
        toast(res.data.message)
        navigate("/auth/otp", { state: values })
      }
    }
    catch (error) {
      console.log(error)
      toast(error.response.data.message)
    }
  }
  let { submitCount, errors, touched, setFieldValue, handleSubmit, values } =
    Formik;
  return (

    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <Link to="/" className="h1"><b>Admin</b>Eats</Link>
          </div>
          <div className="card-body">
            <p className="login-box-msg">You forgot your password? Here you can easily retrieve a new password.</p>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationCheck}
              onSubmit={onSubmit}
            >

              <Form>
                <div className="input-group">
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    className={`form-control ${submitCount > 0 ? errors?.email && touched?.email ? "is-invalid" : "is-valid" : ""}`}
                  />

                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                <div className="text-danger">
                  <ErrorMessage name="email" />
                </div>
                <div className="row">
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary btn-block">Request new password</button>
                  </div>

                </div>
              </Form>
            </Formik>
            <p className="mt-3 mb-1">
              <Link to="/auth/login">Login</Link>
            </p>
          </div>

        </div>
      </div>

    </div>

  )
}

export default ForgetPassword