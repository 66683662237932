import React, { useState } from 'react'
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { forgetPassword, otpVerify } from '../../Services/service';
const Otp = () => {
  const [otp, setOtp] = useState('')
  const location = useLocation()
  console.log(location)
  const navigate = useNavigate()

  const onSubmit = async (values) => {
    const body = {
      email: location?.state?.email
    }

    try {
      const res = await forgetPassword(body);
      if (res.status === 200) {
        toast(res.data.message)
        setOtp('');
      }
    }
    catch (error) {
      console.log(error)
      toast(error.response.data.message)
      setOtp('');
    }
  }

  const otpEnter = async () => {
    const body = {
      otp: otp,
      email: location?.state?.email ? location?.state?.email : "admin@getnada.com"
    }
    try {
      const response = await otpVerify(body)
      if (response.status === 200) {
        toast(response.data.data.message)
        navigate("/auth/resetpassword", { state: location?.state?.email })
      }
    }
    catch (error) {
      toast(error.response.data.message)
      setOtp('');
    }

  }


  return (
    <>
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <a href="#" className="h1"><b>Admin</b>Eats</a>
            </div>
            <div className="card-body">
              <p className="login-box-msg">Enter your Code here</p>

              <div className="input-group">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  inputStyle="inputStyle"
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>

              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block" onClick={() => otpEnter()}>Verify</button>
                </div>

              </div>

              <p className="mt-3 mb-1">
                <p className="forget-password"  >Didn't receive the code?</p>
                <a type="button" className="forget-password" onClick={() => onSubmit()}>Resend</a>
              </p>
            </div>

          </div>
        </div>

      </div>



    </>
  )
}

export default Otp