import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom';
import {changeDishStatus, deleteIngredients, getAllIngredients} from '../../Services/service';
import Pagination from '../../Component/Pagination';
import { TransitEnterexit } from '@mui/icons-material';
import { toast } from 'react-toastify';
import * as constant from "../../constant";


const IngredientsList = () => {
  // const history = useHistory()
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const [Data, setData] = useState([]);
  const [List, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [order, setOrder] = useState({
    status: false,
    name: false
  })
  async function getList(page = 1) {
    const response = await getAllIngredients({ pageNumber: page, pageSize: 10 });
    if (response.status === 200) {
      setList(response.data.data);
      setData(response.data.data)
      setTotalPage(response.data.coundData.totalPages);
      return null;
    }
  }

  useEffect(() => {
    getList(1)
  }, [totalPage]);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getList(pageNumber);
  };


  const deleteHandler = async (id) => {
    var result = window.confirm("Want to delete?");
    if (result) {
        const response = await deleteIngredients({ id: id });
        if (response.status === 200) {
          getList(1);

        }
    }
}


  const handleStatus = async (index, status) => {
    const confirm = window.confirm("Are you sure wanna change the status");

    if (confirm) {
      console.log(List[index]);
      const updatedList = [...List];
      const toggledStatus = updatedList[index].status === 0 ? 1 : 0;
      updatedList[index] = { ...updatedList[index], status: toggledStatus };
      setList(updatedList);
      try {
        const response = await changeDishStatus({ id: updatedList[index]._id, status: toggledStatus });
        if (response.status === 200) {
          toast(response.data.message);
        }
      } catch (error) {
        toast(error.response.data.message)
      }
    }
  }


  const handleSort = (type) => {
    setOrder(prev => ({
      ...prev,
      [type]: !prev[type]
    }));

    const isAscending = !order[type];

    switch (type) {
      case "status":
        if (isAscending) {
          let sorted = List.sort((a, b) => a.status - b.status);
          setList(sorted)
        } else {
          let sorted = List.sort((a, b) => b.status - a.status);
          setList(sorted)
        }

        break;
      case "name":
        if (isAscending) {
          let sorted = List.sort((a, b) => a.first_name.localeCompare(b.first_name))
          setList(sorted)
        } else {
          let sorted = List.sort((a, b) => b.first_name.localeCompare(a.first_name))
          setList(sorted)
        }
        break;
      default:
        break;
    }

    console.log(List)
  };



  const handleSearch = (e) => {
    let filtered = [];
    const input = e.target.value.toLowerCase();
    setList(Data)
    if (input) {
      filtered = List.filter((el) => {
        return Object.values(el).some((val) =>
          String(val).toLowerCase().includes(input)
        );
      });
      setList(filtered)
    }
  }






  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Ingredients List</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active">Ingredients List</li>
              </ol>
            </div>
          </div>
        </div>
      </section>


      <section className="content">
        <div className='my-2 d-flex justify-content-between'>
          <div className='mr-sm-5 mr-1 flex-fill'>
            {/* <input className="form-control me-2" type="search" placeholder="" onChange={handleSearch} /> */}
          </div>
          <Link className='btn btn-success' to={`./add`}> <i className='fa fa-plus mx-1'></i> Add Ingredients </Link>
        </div>
        <div className="card">
          <div className="card-body p-0">
            <table className="table table-striped projects">
              <thead>
                <tr>
                  <th >
                    #
                  </th>
            
                  <th >
                    Name
              
                  </th>

              
                 
                  <th >
                 
                  </th>
                </tr>
              </thead>
              <tbody>
                {List?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      #{index + 1}
                    </td>
                 

                    <td>
                      <span>
                        {item.name}
                      </span>


                    </td>

             
                    <td className="project-actions text-right">
                     

                      <Link to={`./edit/${item._id}`} className="btn btn-info btn-sm">
                        <i className="fas fa-pencil-alt">
                        </i>
                        Edit
                      </Link>

                      <a onClick={() => {
                                                deleteHandler(item._id)
                                            }} className="btn btn-danger btn-sm" >
                                                <i className="fas fa-trash">
                                                </i>
                                                Delete
                      </a>
                    

                    </td>
                  </tr >
                ))}
              </tbody >
            </table >
            {totalPage ? <Pagination
                            currentPage={currentPage}
                            totalPages={totalPage}
                            onPageChange={handlePageChange}
                        /> : ""}
          </div >

        </div >


      </section >





    </>
  )
}

export default IngredientsList;




const SortIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.w ? props.w : 20}
    height={props.h ? props.h : 20}
    viewBox="0 0 24 24"
    style={{
      fill: "rgba(0, 0, 0, 1)",
      transform: "",
      msfilter: "",
    }}
    {...props}
  >
    <path d="M8 16H4l6 6V2H8zm6-11v17h2V8h4l-6-6z" />
  </svg>
);

