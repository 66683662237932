import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    getAllReferral,
    getReferral,
    addReferral,
    updateReferral,
    deleteReferral,
} from '../../Services/service';
import parse from 'html-react-parser';
import { base } from '../../constant';

const ReferralList = () => {

    const [List, setList] = useState([]);

    useEffect(() => {

        async function getList() {
            const response = await getAllReferral();
            if (response.status === 200) {
                setList(response.data.data);
                return null;
            }
        }

        getList()

    }, []);

    const dateFormat = (dateString) => {
        const dateObject = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-US', options);
        return formattedDate
    }
    const deleteHandler = async (id) => {
        var result = window.confirm("Want to delete?");
        if (result) {
            const response = await deleteReferral({ id: id });
            if (response.status === 200) {
                setList(List.filter(record => record._id !== id));
            }
        }
    }


    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Referral Managment</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Referral Managment</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">


                <div className="card-header">
                    <div className="card-tools">
                        <Link to='./add' className="btn btn-info btn-sm float-right" >
                            Add Referral
                        </Link>
                    </div>
                </div>


                <div className="card">

                    <div className="card-body p-0">
                        <table className="table table-striped projects">
                            <thead>
                                <tr>
                                    <th >
                                        #
                                    </th>
                                    <th style={{ width: "20%" }}>
                                        Code
                                    </th>
                                    <th style={{ width: "20%" }}>
                                        Validity
                                    </th>



                                    <th >
                                    </th>
                                </tr>
                            </thead>
                            <tbody>



                                {List.length !== 0 ? List.map((item, index) => {

                                    return <tr key={index + 1}>
                                        <td>
                                            #{index + 1}
                                        </td>
                                        <td>
                                            <a>
                                                {item.code}
                                            </a>

                                        </td>
                                        <td>
                                            <a>
                                                {dateFormat(item.validity)}
                                            </a>

                                        </td>


                                        <td className="project-actions text-right">

                                            <Link to={`./edit/${item._id}`} className="btn btn-info btn-sm" >
                                                <i className="fas fa-pencil-alt">
                                                </i>
                                                Edit
                                            </Link>
                                            <a onClick={() => {
                                                deleteHandler(item._id)
                                            }} className="btn btn-danger btn-sm" >
                                                <i className="fas fa-trash">
                                                </i>
                                                Delete
                                            </a>
                                        </td>
                                    </tr>
                                }) : ''}

                            </tbody>
                        </table>

                        {List.length == 0 &&  <h4 className='text-center'>No Referral Found</h4>}

                    </div>
                </div>
            </section>

        </>
    )
}

export default ReferralList;