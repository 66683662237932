import { FormikProvider, Field, Form, Formik, ErrorMessage, useFormik } from 'formik'
import * as yup from "yup";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { addTestimonials } from '../../Services/service';
import { toast } from 'react-toastify';

const AddTestimonials = () => {

  const formData = new FormData();


  const initialValues = {
    name: '',
    comment: '',
    image: ''
  }


  const validationCheck = yup.object().shape({
    name: yup.string().trim().required("Name is required"),
    image: yup.string().trim().required("Image is required"),
    comment: yup.string().trim().required("Comment is required")
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationCheck,
    onSubmit: async (values) => {
      try {
        const response = await addTestimonials(values);
        if (response.status === 200) {
          toast(response.data.message);
        }
      } catch (error) {
        toast(error.response.data.message)
      }
    }
  });


  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Add Faq</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active">Add Testimonials</li>
              </ol>
            </div>
          </div>
        </div>
      </section>


      <section className="content">



        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Detail</h3>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                        <i className="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="inputName">Name</label>
                      <Field
                        type="name"
                        name="name"
                        placeholder="Name"
                        className={`form-control`}
                      />


                      <div className="text-danger">
                        <ErrorMessage name="name" />
                      </div>
                    </div>


                    <div className="form-group">
                      <label htmlFor="inputName">Image</label>
                      <input id="file" name="image" type="file" onChange={(event) => {
                        formik.setFieldValue("image", event.currentTarget.files[0]);
                        formData.append("file", event.currentTarget.files[0]);
                      }} />


                      <div className="text-danger">
                        <ErrorMessage name="image" />
                      </div>
                    </div>



                    <div className="form-group">
                      <label htmlFor="inputDescription">Comment</label>
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          formik.setFieldValue('comment', data);
                        }}
                      />


                      <div className="text-danger">
                        <ErrorMessage name="comment" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-12">
                <input type="submit" value="Create Testimonial" className="btn btn-success " />
              </div>
            </div>

          </Form>
        </FormikProvider>
      </section>

    </>
  )
}

export default AddTestimonials;