import React, { useEffect, useState } from 'react';
import { FormikProvider, Field, Form, ErrorMessage, useFormik } from 'formik'
import * as yup from "yup";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { updateCms, getCms } from '../../Services/service';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const EditCms = () => {

  const { slug } = useParams();
  const [initialValues, setInitialValue] = useState({
    title: "",
    content: ""
  });



  useEffect(() => {

    const api_call = async () => {
      const response = await getCms({ contentType: slug });
      if (response.status === 200) {

        console.log(response.data.data);
        const { title, content } = response.data.data;
        setInitialValue({ title, content });
      }
    }

    api_call();

  }, []);




  const validationCheck = yup.object().shape({
    title: yup.string().trim().max(150).required("Title is required"),
    content: yup.string().trim().required("Content is required")
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationCheck,
    onSubmit: async (values) => {
      try {
        values.contentType = slug;
        const response = await updateCms(values);
        if (response.status === 200) {
          window.history.back();
          toast(response.data.message);
        }
      } catch (error) {
        toast(error.response.data.message)
      }
    }
  });




  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Edit Cms</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active">Edit Cms</li>
              </ol>
            </div>
          </div>
        </div>
      </section>


      <section className="content">



        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="inputName">Title</label>
                      <Field
                        type="title"
                        name="title"
                        placeholder="title"
                        className={`form-control`}

                      />


                      <div className="text-danger">
                        <ErrorMessage name="title" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputDescription">Content</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={initialValues.content}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          formik.setFieldValue('content', data);
                        }}
                      />


                      <div className="text-danger">
                        <ErrorMessage name="content" />
                      </div>

                    </div>



                  </div>

                </div>

              </div>

            </div>
            <div className="row">
              <div className="col-12">
                <input type="submit" value="Update Cms" className="btn btn-success " />
              </div>
            </div>

          </Form>
        </FormikProvider>
      </section>

    </>
  )
}

export default EditCms;