import React, { useEffect, useState } from 'react';
import { FormikProvider, Field, Form, Formik, ErrorMessage, useFormik } from 'formik'
import * as yup from "yup";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { getUser, addUser } from '../../Services/service';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

//"admin", "vendor", "customer", "subAdmin"
const UserAdd = () => {

    const navigate = useNavigate();
    //{ email, phone, country_code, password, confirm_password } 
    const { role } = useParams();
    const [initialValues, setInitialValue] = useState({
        first_name: "",
        email: "",
        confirm_password: "",
        password: "",
        phone: "",
    });



    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validationCheck = yup.object().shape({
        first_name: yup.string().required("Name is required"),
        email: yup.string().required("Email name is required"),
        confirm_password: yup.string().required("confirm password is required"),
        password: yup.string()
            .required("Password is required")
            .min(6, "Password is too short")
            .max(50, "Password is too long"),
        phone: yup.string()
            .required("Phone Number is required")
            .matches(phoneRegExp, 'Phone number is not valid')
            .min(8, "Phone Number is too short")
            .max(12, "Phone Number is too long"),
    })

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: validationCheck,
        onSubmit: async (values) => {
            try {
                const response = await addUser({ role: role, ...values });
                if (response.status === 200) {
                    toast(response.data.message);
                    navigate('/admin/user/customer');
                }
            } catch (error) {
                toast.error(error.response.data.message)
            }
        }
    });

    return (
        <>

            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Add User</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Add User</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Detail</h3>

                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                    <div className="form-group">
                                            <label htmlFor="inputName">User Name</label>
                                            <Field
                                                type="text"
                                                name="first_name"
                                                placeholder="User Name"
                                                className={`form-control`}

                                            />
                                            <div className="text-danger">
                                                <ErrorMessage name="first_name" />
                                            </div>
                                        </div>


                                        <div className="form-group">
                                            <label htmlFor="inputName">Email</label>
                                            <Field
                                                type="email"
                                                name="email"
                                                placeholder="Email"
                                                className={`form-control`}

                                            />
                                            <div className="text-danger">
                                                <ErrorMessage name="email" />
                                            </div>
                                        </div>
                                    

                                        <div className="form-group">
                                            <label htmlFor="inputName">Phone Number</label>
                                            <Field
                                                type="phone"
                                                name="phone"
                                                placeholder="Phone"
                                                className={`form-control`}

                                            />


                                            <div className="text-danger">
                                                <ErrorMessage name="phone" />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="inputName">Password</label>
                                            <Field
                                                type="password"
                                                name="password"
                                                placeholder="******"
                                                className={`form-control`}

                                            />
                                            <div className="text-danger">
                                                <ErrorMessage name="password" />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="inputName">Confirm Password</label>
                                            <Field
                                                type="password"
                                                name="confirm_password"
                                                placeholder="******"
                                                className={`form-control`}

                                            />
                                            <div className="text-danger">
                                                <ErrorMessage name="confirm_password" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input type="submit" value="Add User" className="btn btn-success " />
                            </div>
                        </div>

                    </Form>
                </FormikProvider>
            </section>


        </>)
}
export default UserAdd;