import React, { useEffect, useState } from "react";
import axios from "axios";
import * as constant from "../../constant";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Link, redirect, useNavigate ,useParams} from "react-router-dom";
import * as yup from "yup";
import TagInput from "../../Component/TagInput";
// import TimePicker from "@ashwinthomas/react-time-picker-dropdown";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { Form, FormikProvider, useFormik, FieldArray, ErrorMessage } from "formik";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import Modal from "react-bootstrap/Modal";
import { getUser, updateUser } from '../../Services/service';
import PlacesAutocomplete,{geocodeByAddress,getLatLng} from 'react-places-autocomplete';


function VendorEdit({ setStep }) {

  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [vendorImage, setProfileImageUrl] = useState("/images/dish-img.png");

  const [initialValues, setInitialValue] = useState( {
    profile_image: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    vendor_id: "",
    bussiness_name: "",
    photos: "",
    old_photos:[],
    country: "",
    city: "",
    state: "",
    zip: "",
    lat:"",
    lng:"",
    address_line_1: "",
    address_line_2: "",
    tax_id: "",
    identification_type: "",
    identification_number: "",
    license_number: "",
    instruction: "",
    vendorTypeName: "",
    tags: []
  });
  const [file, setFiles] = useState([]);
  const [tags, setTags] = useState([]);
  const [vendorTypeData, setVendorTypeData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("chef");
  const [modalShow, setModalShow] = React.useState(false);
  const [openTime, setOpenTime] = React.useState("");
  const [refresh, setRefresh] = useState(false);


  const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
    <div className="autocomplete-root">
      <input {...getInputProps({
            placeholder: 'Address Line 1', // Add the placeholder here
          })} />
      <div className="autocomplete-dropdown-container">
       
        {suggestions.map(suggestion => (
          <div {...getSuggestionItemProps(suggestion)}>
            <span>{suggestion.description}</span>
          </div>
        ))}
      </div>
    </div>
  );


  const handleSelect = async (value) => {


    // Use geocodeByAddress to get location details
    const results = await geocodeByAddress(value);
    if (results.length > 0) {
      const location = results[0];
      let LatLong={lat:0,lng:0};
      await geocodeByAddress(value).then(results => getLatLng(results[0]))
      .then(latLng => {  LatLong =  latLng; })
      .catch(error => console.error('Error', error));

    

      const { address_components } = location;
      const components = address_components;

      const countryComponent = components.find((component) => component.types.includes("country"));
      const stateComponent = components.find((component) => component.types.includes("administrative_area_level_1"));
      const cityComponent = components.find((component) => component.types.includes("locality"));
      const zipComponent = components.find((component) => component.types.includes("postal_code"));

      setValues((prevValues) => ({
        ...prevValues,
        address_line_1: location.formatted_address,
        country: countryComponent?.long_name  || "",
        state: stateComponent?.long_name || "",
        city: cityComponent?.long_name  || "",
        zip: zipComponent?.long_name  || "",
        lat:LatLong.lat || 0,
        lng:LatLong.lng || 0,
      }));

     
      
     
    }
  }





  function Step2Popup(props) {
    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
        <div className="modal-head d-flex align-items-center justify-content-center">
          <h1 className="modal-title" id="exampleModalLabel">
            Second Step Completed.
          </h1>
        </div>
        <div className="modal-body">
          <p className="text-center">Yay!! You have completed the second step.</p>
        </div>
        <div className="bottom-modal-footer text-center mt-3">
          <button
            type="button"
            onClick={() => {
              {
                props.onHide();
              }
              {
                props.step();
              }
            }}
            className="btn btn-primary">
            Continue
          </button>
        </div>
      </Modal>
    );
  }
 

  const vendorType = async () => {
    try {
      
      let token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };

      const response = await axios.get(constant.dashBoardUrl + "/getAllVendorType", {headers} );
      setVendorTypeData(response.data.data);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };






  useEffect(() => {

    const api_call = async () => {
      const response = await getUser({ id: id });
      if (response.status === 200) {
        const dt= response.data.data;

      
        setSelectedOption(dt.vendor_type);
        setProfileImageUrl(constant.base+"/"+dt.profile_image);

        let my_data = {
          profile_image:dt.profile_image,
          first_name:dt.first_name, 
          last_name:dt.last_name, 
          email:dt.email, 
          phone:dt.phone, 
          vendor_id:dt.vendor_id,
          bussiness_name:dt.bussiness_name,
          address_line_1:dt.address_line_1,
          address_line_2:dt.address_line_2,
          country:dt.country,
          photos:dt.photos,
          old_photos:dt.photos,
          city:dt.city,
          state:dt.state,
          zip:dt.zip,
          lat:dt.lat,
          lng:dt.lng,
          tax_id:dt.tax_id,
          identification_number:dt.identification_number,
          license_number:dt.license_number,
          instruction:dt.instruction,
          identification_type:dt.identification_type,
          tags:dt.tags,
          status:dt.status }


        setInitialValue(my_data);

        setIsLoading(false);

      }
    }

    api_call();
    vendorType();

    
  }, [refresh]);

  const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes
  const isValidFileType = (fileName, fileType) => {
    return /\.(jpg|jpeg|png)$/i.test(fileName);
  };

  const schema = yup.object().shape({
    phone: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .required("Phone is required"),
    email: yup.string().email("Enter valid email").required("Email is required"),
   

    // profile_image: yup
    //   .mixed()
    //   .required("Profile image is required")
    //   .test("is-valid-type", "Not a valid image type", (value) => isValidFileType(value && value.name.toLowerCase(), "image"))
    //   .test("is-valid-size", "Max allowed size is 1MB", (value) => value && value.size <= MAX_FILE_SIZE),
    first_name: yup.string().required("First Name is required").max(30, "maximum 30 character allowed"),
    last_name: yup.string().required("Last Name is required").max(30, "maximum 30 character allowed"),
    vendor_id: yup.string().required("Vendor Id is required"),
    bussiness_name: yup.string().required("Business Name is required").min(3, "Please enter a Business name more than 3 character"),
    // photos: yup
    //   .mixed()
    //   .required("Photo is required")
    //   .test("is-present", "Photos must be less than five", (value) => value && value.length < 6),
    country: yup.string().required("Country is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    zip: yup.string().required("Zip Code is required"),
    address_line_1: yup.string().required("Address is required"),
    tax_id: yup.string().required("Tax Id is required").min(30, "The field should be large enough for at least 30 alphanumeric digits."),
    identification_type: yup.string().required("Identification is required"),
    identification_number: yup.string().required("Identification Number is required"),
    instruction: yup.string().min(3, "Please enter atleast 3 character"),

    tags: yup
      .array()
      .required("Tags are required")
      .of(
        yup
          .string()
          .trim() // Remove leading and trailing whitespaces
          .test("no-spaces", "Tag cannot be empty or contain only spaces", (value) => {
            return value && value.trim() !== "";
          })
      )
    
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:initialValues,

    validationSchema: schema,
    onSubmit: async (data, { setErrors, setSubmitting }) => {
     
  

     

      const form_data = new FormData();

      
      form_data.append("vendor_type", selectedOption); 

      for (var key in data) {
        if (typeof data[key] == "object") {
          if(key=='profile_image'){
            form_data.append(key, data[key]);
          }else{
            form_data.append(key, JSON.stringify(data[key]));
          }
          
        } else {
          form_data.append(key, data[key]);
        }
      }


   





      let files = data.photos;
      for (let i = 0; i < files.length; i++) {
        form_data.append("photos", files[i]);
      }
      
      try {
        
        let token = localStorage.getItem("token");
        const headers = { Authorization: "Bearer " + token };
        const response = await axios.put(constant.dashBoardUrl + "/updateVendorAccount", form_data, {params:{id:id},headers});


        if (response.data.status) {
          
          toast.success("Vendor added successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/admin/user/vendor");

        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
  });
  const vendorTypeAdd = async () => {
    let token = localStorage.getItem("token");
    const headers = { Authorization: "Bearer " + token };
    try {
      const response = await axios.post(
        constant.dashBoardUrl + "/addVendorType",
        { name: values.vendorTypeName },
        {
          headers
        }
      );
      if (response.data.status) {
        setRefresh(!refresh);
        setSelectedOption(values.vendorTypeName);
        setFieldValue("vendorTypeName","");
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    handleChange,
    handleSubmit,
    setValues,
    getFieldProps,
    setFieldValue,
    setFieldError,
    setErrors,
  } = formik;

  const changeHandler = (event) => {
    var file = event.target.files;

  

    for (let i = 0; i < file.length; i++) {
      if (file[i].type == "image/png" || file[i].type == "image/jpg" || file[i].type == "image/jpeg") {
        if (file[i].size <= 1 * 1024 * 1024) {
          setFiles(file);
          setValues({ ...values, photos: file });
        } else {
          toast.error("Size must be less than 1 MB", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setFieldError("photos", "Size must be less than 1 MB");
        }
      } else {
        toast.error("Invalid File Format", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setFieldError("photos", "Invalid File Format");
      }
    }
  };

  const profileImageHandler = (e) => {
    let profileImage = e.target.files[0];

    if (e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpg" || e.target.files[0].type == "image/jpeg") {
      if (e.target.files[0].size <= 1 * 1024 * 1024) {
        let image_url = URL.createObjectURL(profileImage);
        setProfileImageUrl(image_url);
        setValues({ ...values, profile_image: profileImage });
      } else {
        toast.error("Size must be less than 1 MB", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setFieldError("profile_image", "Size must be less than 1 MB");
      }
    } else {
      toast.error("Invalid File Format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFieldError("profile_image", "Invalid File Format");
    }
  };

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const tagHandler = (tagArray) => {
    setTags(tagArray);
    setValues({ ...values, tags: tagArray });
  };

  const handleOptionSelect = (name) => {
    setSelectedOption(name);
  };


  return (
    <>
  {isLoading ? (
        <p>loading..</p>
      ) : (
      

      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}>

         
          <div className="form-box signup-form col-md-8 m-auto">
            <div className="row">
              <div className="col-md-12" style={{ marginBottom: "30px" }}>
                <div class="upload-photo">
                  <div class="avatar-upload">
                    <div class="avatar-edit">
                      <input
                        id="profileImage"
                        type="file"
                        required
                        onChange={profileImageHandler}
                        accept="image/png, image/jpeg,image/jpg"
                        validateOnChange={true}
                        validateOnBlur={true}
                      />

                      <label for="profileImage" class="img-upload-icon">
                        <i class="ri-edit-box-line"></i>
                      </label>
                    </div>
                    <div class="avatar-preview">
                      <img className="avatar-preview-img" src={vendorImage} />
                      <span class="validation-error">{errors.profile_image && touched.profile_image && errors.profile_image}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group mb-4 col-md-6">
                <label className="form-label">First Name *</label>
                <input
                  type="text"
                  placeholder=""
                  {...getFieldProps("first_name")}
                  className="form-control"
                />
                <span class="validation-error">{errors.first_name && touched.first_name && errors.first_name}</span>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <label className="form-label">Last Name *</label>
                  <input type="text"   {...getFieldProps("last_name")} placeholder="" className="form-control" />

                  <span class="validation-error">{errors.last_name && touched.last_name && errors.last_name}</span>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group mb-4">
                  <label className="form-label">Email *</label>
                  <input type="text"  {...getFieldProps("email")} placeholder="" className="form-control" />
                  <span class="validation-error">{errors.email && touched.email && errors.email}</span>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group mb-4">
                  <label className="form-label">Mobile Number *</label>
                  <input type="text"  {...getFieldProps("phone")}  className="form-control" />
                  <span class="validation-error">{errors.phone && touched.phone && errors.phone}</span>
                </div>
              </div>

              {/* <div className="col-md-6">
                <div className="form-group mb-4">
                  <label className="form-label">Password</label>
                  <input type="password"   name="password" className="form-control" />
                  <span class="validation-error">{errors.password && touched.password && errors.password}</span>
                </div>
              </div> */}


              <div className="form-group mb-4 col-md-6">
                <label className="form-label">Vendor ID *</label>
                <input type="text" readOnly="true" placeholder="" {...getFieldProps("vendor_id")} className="form-control" />
                <span class="validation-error">{errors.vendor_id && touched.vendor_id && errors.vendor_id}</span>
              </div>
              <div className="form-group col-md-6  mb-4">
                <label className="form-label">Business Name *</label>
                <input
                  type="text"
                  placeholder="Enter Business Name"
                  className="form-control"
                  {...getFieldProps("bussiness_name")}
                  value={values.bussiness_name}
                  onChange={handleChange}
                  maxLength={40}
                />
                <span class="validation-error">{errors.bussiness_name && touched.bussiness_name && errors.bussiness_name}</span>
              </div>

              <div className="form-group col-md-12">
                <label className="form-label">Upload Image </label>
                <div className="file-upload-wrapper" data-text="Upload Image" style={{background:"white"}}>
                  <input type="file" onChange={changeHandler} multiple accept="image/png, image/jpeg,image/jpg" />

                  <button type="button" className="upload-btn">
                    <i className="ri-upload-line"></i>
                  </button>
                </div>
                <span class="validation-error">{errors.photos && touched.photos && errors.photos}</span>

                <div className="upload-img-list">
                  {Array.from(file).map((file, index) => (
                    <figure class="upload-img-box">
                      <img src={URL.createObjectURL(file)} />
                    </figure>
                  ))}

                {Array.from(values.old_photos).map((item, index) => (
                    <figure class="upload-img-box">
                      <img src={constant.base+"/"+item} />
                    </figure>
                  ))}
                </div>
              </div>

              <div className="form-group mb-4 col-md-6">
                <label className="form-label">Address Line 1 *</label>


                <PlacesAutocomplete  value={values.address_line_1}   onSelect={handleSelect}  onChange={value => {setFieldValue('address_line_1', value )}} placeholder="Enter Address line 1">
                  {renderFunc}
               </PlacesAutocomplete>


                {/* <Autocomplete
                  id="address_line_1"
                  placeholder="Enter Address line 1"
                  value={values.address_line_1}
                  apiKey="AIzaSyBh6geA7dZFyAyooXtoY5vy8s4c9CoCDiY"
                  className="form-control"
                  onPlaceSelected={(place) => {
                    const components = place.address_components || [];

                    const countryComponent = components.find((component) => component.types.includes("country"));
                    const stateComponent = components.find((component) => component.types.includes("administrative_area_level_1"));
                    const cityComponent = components.find((component) => component.types.includes("locality"));
                    const zipComponent = components.find((component) => component.types.includes("postal_code"));

                    setValues((prevValues) => ({
                      ...prevValues,
                      address_line_1: place.formatted_address,
                      country: countryComponent?.long_name || prevValues.country || "",
                      state: stateComponent?.long_name || prevValues.state || "",
                      city: cityComponent?.long_name || prevValues.city || "",
                      zip: zipComponent?.long_name || prevValues.zip || "",
                    }));
                  }}
                  onChange={handleChange}
                /> */}

                <span class="validation-error">{errors.address_line_1 && touched.address_line_1 && errors.address_line_1}</span>
              </div>
              <div className="form-group mb-4 col-md-6">
                <label className="form-label">Address Line 2</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Address line 2"
                  {...getFieldProps("address_line_2")}
                  value={values.address_line_2}
                  onChange={handleChange}
                  maxLength={60}
                />
                <span class="validation-error">{errors.address_line_2 && touched.address_line_2 && errors.address_line_2}</span>
              </div>
              <div class="form-group mb-4 col-md-6">
                <label class="form-label">Country Of Origin *</label>
                <input type="text" className="form-control" placeholder="Enter Tax ID number" {...getFieldProps("country")}  value={values.country}/>
                <span class="validation-error">{errors.country && touched.country && errors.country}</span>
              </div>
              <div class="form-group mb-4 col-md-6">
                <label class="form-label">City *</label>
                <input type="text" className="form-control" placeholder="Enter Tax ID number" {...getFieldProps("city")} value={values.city} />
                <span class="validation-error">{errors.city && touched.city && errors.city}</span>
              </div>
              <div class="form-group mb-4 col-md-6">
                <label class="form-label">State *</label>
                <input type="text" className="form-control" placeholder="Enter Tax ID number" {...getFieldProps("state")} value={values.state}/>
                <span class="validation-error">{errors.state && touched.state && errors.state}</span>
              </div>
              <div className="form-group mb-4 col-md-6">
                <label className="form-label">Zip Code *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Tax ID number"
                  {...getFieldProps("zip")}
                  value={values.zip}
                  onChange={handleChange}
                  maxLength={10}
                />

                <span class="validation-error">{errors.zip && touched.zip && errors.zip}</span>
              </div>

              <div className="form-group mb-4 col-md-6">
                <label className="form-label">Tax ID Number *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Tax ID number"
                  {...getFieldProps("tax_id")}
                  value={values.tax_id}
                  onChange={handleChange}
                />
                <span class="validation-error">{errors.tax_id && touched.tax_id && errors.tax_id}</span>
              </div>
              <div className="form-group mb-4 col-md-6">
                <label className="form-label">Type Of Identification *</label>
                <select className="form-select" {...getFieldProps("identification_type")}>
                  <option value=""> Select Type Of Identification</option>
                  <option value="passport">Passport</option>
                  <option value="national_id">National ID </option>
                  <option value="driver_license">Driver's License</option>
                  <option value="state_id">State ID</option>
                </select>
                <span class="validation-error">
                  {errors.identification_type && touched.identification_type && errors.identification_type}
                </span>
              </div>

              <div className="form-group mb-4 col-md-6">
                <label className="form-label">Identification Number *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Identification number"
                  {...getFieldProps("identification_number")}
                  value={values.identification_number}
                  onChange={handleChange}
                  maxLength={22}
                />
                <span class="validation-error">
                  {errors.identification_number && touched.identification_number && errors.identification_number}
                </span>
              </div>
              <div className="form-group mb-4 col-md-6">
                <label className="form-label"> Business License Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter  Business license number"
                  {...getFieldProps("license_number")}
                  value={values.license_number}
                  onChange={handleChange}
                  maxLength={22}
                />
                <span class="validation-error">{errors.license_number && touched.license_number && errors.license_number}</span>
              </div>
              <div className="form-group mb-4 col-md-12">
                <label className="form-label">Vendor Type</label>
                <div className="dropdown cutom-dropdown" style={{background:"white"}}>
                  <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {selectedOption}
                  </button>
                  <ul className="dropdown-menu">
                    <li className="active-link">
                      <a type="button" data-bs-toggle="modal" data-bs-target="#add-vendor">
                        {" "}
                        <i className="ri-add-line"></i>
                        Add Vendor Type
                      </a>
                    </li>
                    {vendorTypeData.map((item, index) => {
                      return (
                        <li key={index}>
                          <a className="dropdown-item" onClick={() => handleOptionSelect(item.name)}>
                            {item.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="form-group mb-4 col-md-12">
                <label className="form-label"> Instructions For Customers/Delivery Driver </label>
                <input
                  type="text"
                  name=""
                  className="form-control"
                  placeholder="Eg: order instructions"
                  {...getFieldProps("instruction")}
                  value={values.instruction?.replace(/ +/g, " ")}
                  onChange={handleChange}
                  maxLength={50}
                />
                <span class="validation-error">{errors.instruction && touched.instruction && errors.instruction}</span>
              </div>
              <div className="form-group mb-4 col-md-12 ">
                <div className="label-box d-flex justify-content-between">
                  <label className="form-label">Tags (Your Specialties)</label>
                  <span className="tool-tip-box con-tooltip top">
                    <i className="ri-information-line"></i>
                    <div className="tooltip ">
                      <p>Top</p>
                    </div>
                  </span>
                </div>
                <div class="tag-input-box">
                  <div class="input-box">
                 

                     <TagInput
                              get_tags={() =>tagHandler()}
                              initialData={initialValues.tags}
                            /> 

                  </div>
                </div>
                <span class="validation-error">{errors.tags && touched.tags && errors.tags}</span>
              </div>

             
            </div>
          </div>
          <div className="btn-box text-center mt-4">
            <button type="submit" className="btn btn-primary mt-2">
              Update
            </button>
          </div>
        </Form>
      </FormikProvider>

)}
      <Step2Popup
        show={modalShow}
        onHide={() => setModalShow(false)}
        step={() => {
          setStep(3);
        }}
      />

      <div class="modal fade common-modal" id="add-vendor" aria-labelledby="exampleModalLabel" keyboard={false}>
        <div class="modal-dialog">
          <div class="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i class="ri-close-fill"></i>
            </button>
            <div class="modal-head d-flex align-items-center justify-content-center">
              <h1 class="modal-title" id="exampleModalLabel">
                Add Vendor Type
              </h1>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label class="form-label">Vendor Type</label>
                <input
                  type="text"
                  name="vendorTypeName"
                  placeholder="Enter Vendor Type"
                  class="form-control"
                  value={values.vendorTypeName}
                  onChange={handleChange}
                  maxLength={40}
                />
              </div>
              <div class="bottom-modal-footer text-center mt-3">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  class="btn btn-primary"
                  onClick={() => {
                    vendorTypeAdd();
                  }}
                  disabled={values.vendorTypeName === ""}>
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VendorEdit;
