import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { Field, Form, Formik, ErrorMessage } from 'formik'
import * as yup from "yup";
import { login } from '../../Services/service';
import { MainContext } from '../../context/MyContext';

const Login = () => {
    const { setToken } = React.useContext(MainContext);

    const validationCheck = yup.object().shape({
        email: yup.string().required("Email is required"),
        password: yup.string().required("Password is required")
    })
    const navigate = useNavigate();
    const onSubmit = async (values) => {
        try {
            const response = await login(values);
            if (response.status === 200) {
                localStorage.setItem("token", response.data.data.token)
                setToken(response.data.data.token)
                toast(response.data.message)
                navigate("/admin/dashboard")
            }
        } catch (error) {
            toast(error.response.data.message)
        }
    }
    let { submitCount, errors, touched, setFieldValue, handleSubmit, values } = Formik;
    return (
        <div className="hold-transition login-page">
            <div className="login-box">

                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <Link to={"/"} className="h1"><b>Admin</b>Eats</Link>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Sign in to start your session</p>

                        <Formik
                            initialValues={{
                                email: "",
                                password: ""
                            }}
                            validationSchema={validationCheck}
                            onSubmit={onSubmit}
                        >
                            <Form>
                                <div className="input-group">
                                    <Field
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        className={`form-control ${submitCount > 0 ? errors?.email && touched?.email ? "is-invalid" : "is-valid" : ""}`}
                                    />

                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"></span>
                                        </div>
                                    </div>

                                </div>

                                <div className="text-danger">
                                    <ErrorMessage name="email" />
                                </div>
                                <div className="input-group">
                                    <Field
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        className={`form-control ${submitCount > 0 ? errors?.password && touched?.password ? "is-invalid" : "is-valid" : ""}`}
                                    />


                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock"></span>
                                        </div>
                                    </div>

                                </div>

                                <div className="text-danger">
                                    <ErrorMessage name="password" />
                                </div>

                                <div className="row">


                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                    </div>

                                </div>
                            </Form>
                        </Formik>
                        <p className="mb-1">
                            <Link to="/auth/forgetpassword">I forgot my password</Link>
                        </p>

                    </div>

                </div>
            </div>
        </div>

    )
}

export default Login