import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter ,RouterProvider } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MainContextProvider } from './context/MyContext';
import App from './App';
// import "react-tagsinput/react-tagsinput.css";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MainContextProvider>
    <BrowserRouter>
      <App />
      <ToastContainer />
    </BrowserRouter>
  </MainContextProvider>
);


