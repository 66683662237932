import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllCms } from '../../Services/service';

const CmsList = () => {

  const [List, setList] = useState([]);

  useEffect(() => {
    async function getList() {
      const response = await getAllCms();
      if (response.status === 200) {
        setList(response.data.data);
        return null;
      }
    }
    getList()
  }, []);

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>CMS Managment</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active">CMS Managment</li>
              </ol>
            </div>
          </div>
        </div>
      </section>


      <section className="content">


        <div className="card">

          <div className="card-body p-0">
            <table className="table table-striped projects">
              <thead>
                <tr>
                  <th >
                    #
                  </th>
                  <th >
                    Title
                  </th>
                  <th >
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  List.map((item, index) => {
                    return <tr key={index + 1}>
                      <td>
                        #{index + 1}
                      </td>
                      <td>
                        <a>
                          {item.title}
                        </a>


                      </td>

                      <td className="project-actions text-right">

                        <Link to={`./edit/${item.contentType}`} className="btn btn-info btn-sm">
                          <i className="fas fa-pencil-alt">
                          </i>
                          Edit
                        </Link>

                      </td>
                    </tr>;
                  })
                }
              </tbody>
            </table>
          </div>

        </div>


      </section>

    </>
  )
}

export default CmsList;