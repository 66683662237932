import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];



  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div >
      {totalPages>0 && 
      
      <ul className="pagination"  >
      <li className='paginate_button page-item previous'
        key="0"
        onClick={currentPage == 1 ? null : () => onPageChange(currentPage - 1)}
      >
        <span className='page-link'>Prev</span>
      </li>

      {pageNumbers.map((pageNumber) => (
        <li
          key={pageNumber}
          className={pageNumber === currentPage ? 'paginate_button page-item active' : 'paginate_button page-item'}
          onClick={() => onPageChange(pageNumber)}
        >
          <span className='page-link'>{pageNumber}</span>
        </li>
      ))}

      <li className="paginate_button page-item next"
        key="-1"
        onClick={totalPages == currentPage ? null : () => onPageChange(currentPage + 1)}
      >
        <span className='page-link'>Next</span>
      </li>

    </ul>
    
      }
     

    </div>
  );
};

export default Pagination;