import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "../Layout/dashboardLayout";
import Dashboard from "../Pages/dashboard";
import SiteSetting from "../Pages/SiteSetting";

// --- User --- //
import UserList from "../Pages/User/UserList";
import VendorList from "../Pages/User/VendorList";
import UserEdit from "../Pages/User/UserEdit";
import UserView from "../Pages/User/UserView";
import VendorView from "../Pages/User/VendorView";


import UserAdd from "../Pages/User/UserAdd";
import VendorAdd from "../Pages/User/VendorAdd";
import VendorEdit from "../Pages/User/VendorEdit";
import AddDish from "../Pages/User/AddDish";
import AddSet from "../Pages/User/AddSet";
import EditDish from "../Pages/User/EditDish";
import EditSet from "../Pages/User/EditSet";
import DishList from "../Pages/User/DishList";
import SetList from "../Pages/User/SetList";

import DishManagmentList from "../Pages/Dish/DishList";
import DishManagmentAdd from "../Pages/Dish/AddDish";
import DishManagmentEdit from "../Pages/Dish/EditDish";
import DishManagmentView from "../Pages/Dish/ViewDish";


import CuisinesList from "../Pages/Cuisines/CuisinesList";
import CuisinesAdd from "../Pages/Cuisines/CuisinesAdd";
import CuisinesEdit from "../Pages/Cuisines/CuisinesEdit";
import CuisinesView from "../Pages/Cuisines/CuisinesView";


import IngredientsList from "../Pages/Ingredients/IngredientsList";
import IngredientsAdd from "../Pages/Ingredients/IngredientsAdd";
import IngredientsEdit from "../Pages/Ingredients/IngredientsEdit";
import IngredientsView from "../Pages/Ingredients/IngredientsView";



import VendorTutorialList from "../Pages/Tutorial/VendorTutorialList";
import VendorTutorialAdd from "../Pages/Tutorial/VendorTutorialAdd";
import VendorTutorialEdit from "../Pages/Tutorial/VendorTutorialEdit";
import VendorTutorialView from "../Pages/Tutorial/VendorTutorialView";


import AppTutorialList from "../Pages/Tutorial/AppTutorialList";
import AppTutorialAdd from "../Pages/Tutorial/AppTutorialAdd";
import AppTutorialEdit from "../Pages/Tutorial/AppTutorialEdit";
import AppTutorialView from "../Pages/Tutorial/AppTutorialView";


import CmsList from "../Pages/Cms/CmsList";
import EditCms from "../Pages/Cms/EditCms";
import FaqList from "../Pages/Faq/FaqList";
import AddFaq from "../Pages/Faq/AddFaq";
import EditFaq from "../Pages/Faq/EditFaq";
import { getAllCms } from '../Services/service';
import TestimonialsList from "../Pages/Testimonials/TestimonialsList";
import AddTestimonials from "../Pages/Testimonials/AddTestimonials";
import EditTestimonials from "../Pages/Testimonials/EditTestimonials";
import CouponAdd from "../Pages/coupon/CouponAdd";
import CouponList from "../Pages/coupon/CouponList";
import CouponEdit from "../Pages/coupon/CouponEdit";
import CouponView from "../Pages/coupon/CouponView";
import BannerList from "../Pages/Banner/BannerList";
import BannerAdd from "../Pages/Banner/BannerAdd";
import BannerEdit from "../Pages/Banner/BannerEdit";
import EmailTemplateList from "../Pages/EmailTemplate/EmailTemplateList";
import EmailTemplateAdd from "../Pages/EmailTemplate/EmailTemplateAdd";
import EditEmailTemplate from "../Pages/EmailTemplate/EmailTemplateEdit";
import EmailTemplateEdit from "../Pages/EmailTemplate/EmailTemplateEdit";
import ReferralList from "../Pages/Referral/ReferralList";
import ReferralEdit from "../Pages/Referral/ReferralEdit";
import ReferralAdd from "../Pages/Referral/ReferralAdd";



export default function AdminRoutes() {
    return useRoutes([
        {
            path: 'admin',
            element: <DashboardLayout />,
            children: [
                { path: 'dashboard', element: <Dashboard /> },
                {
                    path: 'user',
                    children: [
                        { path: ':role', element: <UserList /> },
                        { path: 'vendor', element: <VendorList /> },
                        { path: ':role/add', element: <UserAdd /> },
                        { path: 'vendor/add', element: <VendorAdd /> },
                        { path: 'vendor_edit/:id', element: <VendorEdit /> },
                      
                        { path: ':role/edit/:id', element: <UserEdit /> },
                        { path: ':role/view/:id', element: <UserView /> },
                        { path: 'vendor/view/:id', element: <VendorView /> },
                        
                        { path: 'add_dish/:id', element: <AddDish /> },
                        { path: 'add_set/:id', element: <AddSet /> },
                        { path: 'edit_dish/:id', element: <EditDish /> },
                        { path: 'edit_set/:id', element: <EditSet /> },
                        { path: 'dish_list/:id', element: <DishList /> },
                        { path: 'set_list/:id', element: <SetList /> },

                    ]
                },
                {
                    path: 'coupon',
                    children: [
                        { index: true, element: <CouponList /> },
                        { path: 'add', element: <CouponAdd /> },
                        { path: 'edit/:id', element: <CouponEdit /> },
                        { path: 'view/:id', element: <CouponView /> },
                    ]
                },

                {
                    path: 'dish',
                    children: [
                        { index: true, element: <DishManagmentList /> },
                        { path: 'add', element: <DishManagmentAdd /> },
                        { path: 'edit/:id', element: <DishManagmentEdit /> },
                        { path: 'view/:id', element: <DishManagmentView /> },
                    ]
                },

                {
                    path: 'cuisines',
                    children: [
                        { index: true, element: <CuisinesList /> },
                        { path: 'add', element: <CuisinesAdd /> },
                        { path: 'edit/:id', element: <CuisinesEdit /> },
                        { path: 'view/:id', element: <CuisinesView /> },
                    ]
                },

                {
                    path: 'ingredients',
                    children: [
                        { index: true, element: <IngredientsList /> },
                        { path: 'add', element: <IngredientsAdd /> },
                        { path: 'edit/:id', element: <IngredientsEdit /> },
                        { path: 'view/:id', element: <IngredientsView /> },
                    ]
                },

                {
                    path: 'vendor-tutorial',
                    children: [
                        { index: true, element: <VendorTutorialList /> },
                        { path: 'add', element: <VendorTutorialAdd /> },
                        { path: 'edit/:id', element: <VendorTutorialEdit /> },
                        { path: 'view/:id', element: <VendorTutorialView /> },
                    ]
                },

                {
                    path: 'app-tutorial',
                    children: [
                        { index: true, element: <AppTutorialList /> },
                        { path: 'add', element: <AppTutorialAdd /> },
                        { path: 'edit/:id', element: <AppTutorialEdit /> },
                        { path: 'view/:id', element: <AppTutorialView /> },
                    ]
                },

                {
                    path: 'banner',
                    children: [
                        { index: true, element: <BannerList /> },
                        { path: 'add', element: <BannerAdd /> },
                        { path: 'edit/:id', element: <BannerEdit /> },
                    ]
                },
                {
                    path: 'referral',
                    children: [
                        { index: true, element: <ReferralList /> },
                        { path: 'add', element: <ReferralAdd /> },
                        { path: 'edit/:id', element: <ReferralEdit /> },
                    ]
                },
                {
                    path: 'emailtemplate',
                    children: [
                        { index: true, element: <EmailTemplateList /> },
                        { path: 'add', element: <EmailTemplateAdd /> },
                        { path: 'edit/:id', element: <EmailTemplateEdit /> },
                    ]
                },
                {
                    path: 'cms',
                    children: [
                        { path: '', element: <CmsList /> },
                        { path: 'edit/:slug', element: <EditCms /> },
                    ]
                },
                {
                    path: 'faq',
                    children: [
                        { path: '', element: <FaqList /> },
                        { path: 'add', element: <AddFaq /> },
                        { path: 'edit/:id', element: <EditFaq /> }
                    ]
                },
                {
                    path: 'testimonials',
                    children: [
                        { path: '', element: <TestimonialsList /> },
                        { path: 'add', element: <AddTestimonials /> },
                        { path: 'edit/:id', element: <EditTestimonials /> }
                    ]
                },
                { path: 'site_setting', element: <SiteSetting /> },
               
            ]
        },
        //it maybe show a 404 page if page not exists
        { path: "*", element: <Navigate to="/admin/dashboard" replace /> },
    ])
}