import React from 'react'
import { useNavigate, Link, NavLink } from 'react-router-dom';
import Logo from "../assets/dist/img/AdminLTELogo.png";
import UserLogo from "../assets/dist/img/user2-160x160.jpg";
import { MainContext } from '../context/MyContext';

const Sidebar = () => {

  const { setToken } = React.useContext(MainContext);

  const navigate = useNavigate()
  const handleLogout = () => {
    var result = window.confirm("Want to Logout?");
    if (result) {

      localStorage.clear()
      setToken("")
      navigate("/")
    }
  }

  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">

        <Link to="/admin/dashboard" className="brand-link">

          &nbsp;   &nbsp;   &nbsp;<span className="brand-text font-weight-light">Eats Admin</span>
        </Link>


        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img src={UserLogo} className="img-circle elevation-2" alt="User Image" />
            </div>
            <div className="info">
              <a href="#" className="d-block">Admin</a>
            </div>
          </div>


          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              <li className="nav-item">
                <NavLink to="/admin/dashboard" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>
                    Dashboard

                  </p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/admin/user/customer" className="nav-link ">
                  <i className="nav-icon fas fa-solid fa-user"></i>
                  <p>
                    User Management

                  </p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/admin/user/vendor" className="nav-link ">
                  <i class="nav-icon fa fa-user-plus" aria-hidden="true"></i>
                  <p>
                    Vendor Management
                  </p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/admin/dish" className="nav-link ">
                <i className="nav-icon fas fa-solid fa-copy"></i>
                  <p>
                    Manage Dish
                  </p>
                </NavLink>
              </li>

   


              <li className="nav-item">
                <NavLink to="/admin/vendor-tutorial" className="nav-link ">
                <i className="nav-icon fas fa-solid fa-copy"></i>
                  <p>
                   Vendor Tutorial Management
                  </p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/admin/app-tutorial" className="nav-link ">
                <i className="nav-icon fas fa-solid fa-copy"></i>
                  <p>
                   App Tutorial Management
                  </p>
                </NavLink>
              </li>




              <li className="nav-item">
                <NavLink to="/admin/coupon" className="nav-link ">
                  <i class="nav-icon fa fa-gift" aria-hidden="true"></i>
                  <p>
                    Coupon Management

                  </p>
                </NavLink>
              </li>
              
              <li className="nav-item">
                <NavLink to="/admin/emailtemplate" className="nav-link ">
                  <i class="nav-icon fa fa-envelope" aria-hidden="true"></i>
                  <p>
                    Email Template

                  </p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/admin/banner" className="nav-link ">
                  <i class="nav-icon  fa fa-id-card" aria-hidden="true"></i>
                  <p>
                    Banner Management

                  </p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/admin/referral" className="nav-link ">
                  <i class="nav-icon fa fa-retweet" aria-hidden="true"></i>
                  <p>
                    Referral Management

                  </p>
                </NavLink>
              </li>



              <li className="nav-item ">
                <NavLink to="/admin/cms" className="nav-link ">
                  <i className="nav-icon fas fa-solid fa-copy"></i>
                  <p>
                    Cms Management

                  </p>
                </NavLink>
              </li>


              <li className="nav-item">
                <NavLink to="/admin/faq" className="nav-link ">
                  <i className="nav-icon fas fa-solid fa-question"></i>
                  <p>
                    Faq Management

                  </p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/admin/testimonials" className="nav-link ">
                  <i className="nav-icon fas fa-users"></i>
                  <p>
                    Testimonials
                  </p>
                </NavLink>
              </li>


              <li className="nav-item">
                <NavLink to="/admin/site_setting" className="nav-link ">
                  <i className="nav-icon fas far fa-cog"></i>
                  <p>
                    Site Setting
                  </p>
                </NavLink>
              </li>


              <li className="nav-item">
                <a onClick={handleLogout} className="nav-link ">
                  <i className="nav-icon fas fa-sign-out-alt"></i>
                  <p>
                    Logout
                  </p>
                </a>
              </li>


            </ul>
          </nav>

        </div>

      </aside>

    </>
  )
}

export default Sidebar;