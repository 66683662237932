import React, { useEffect, useState } from 'react';
import { FormikProvider, Field, Form, Formik, ErrorMessage, useFormik } from 'formik'
import * as yup from "yup";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    deleteEmailTemplate,
    updateEmailTemplate,
    getAllEmailTemplate,
    getEmailTemplate,
    addEmailTemplate,
} from '../../Services/service';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { base } from '../../constant';
const EmailTemplateEdit = () => {

    const { id } = useParams();
    const [initialValues, setInitialValue] = useState({});



    useEffect(() => {

        const api_call = async () => {
            const response = await getEmailTemplate({ id: id });
            if (response.status === 200) {
                setInitialValue(response.data.data);
            }
        }

        api_call();

    }, []);




    const validationCheck = yup.object().shape({
        title: yup.string().trim().required("Name is required"),
        subject: yup.string().trim().required("Image is required"),
        slug: yup.string().trim().required("Image is required"),
        content: yup.string().trim().required("Image is required"),
        type: yup.string().trim().required("Image is required"),
        image: yup.string().trim().required("Image is required"),
    })
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: validationCheck,
        onSubmit: async (values) => {

            try {
                values.id = id;
                const response = await updateEmailTemplate(values);
                if (response.status === 200) {
                    toast(response.data.message);
                }
            } catch (error) {
                toast(error.response.data.message)
            }
        }
    });




    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Edit Email</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Edit Email</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">



                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Detail</h3>

                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="inputName">subject</label>
                                            <Field
                                                type="name"
                                                name="subject"
                                                placeholder="subject"
                                                className={`form-control`}

                                            />


                                            <div className="text-danger">
                                                <ErrorMessage name="name" />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="inputName">title</label>
                                            <Field
                                                type="name"
                                                name="title"
                                                placeholder="title"
                                                className={`form-control`}

                                            />


                                            <div className="text-danger">
                                                <ErrorMessage name="title" />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="inputName">content</label>
                                            <Field
                                                type="name"
                                                name="content"
                                                placeholder="content"
                                                className={`form-control`}

                                            />


                                            <div className="text-danger">
                                                <ErrorMessage name="name" />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="inputName">slug</label>
                                            <Field
                                                type="name"
                                                name="slug"
                                                placeholder="slug"
                                                className={`form-control`}

                                            />


                                            <div className="text-danger">
                                                <ErrorMessage name="slug" />
                                            </div>
                                        </div>


                                        <div className="form-group">
                                            <label htmlFor="inputName">type</label>
                                            <Field
                                                type="name"
                                                name="type"
                                                placeholder="type"
                                                className={`form-control`}

                                            />


                                            <div className="text-danger">
                                                <ErrorMessage name="type" />
                                            </div>
                                        </div>

                                        <div className='rounded-3'>
                                            <img src={base + "/" + initialValues.image} style={{ maxHeight: 200 }} className='rounded my-3' />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="inputName">Image</label>
                                            <input id="file" name="image" type="file" onChange={(event) => {
                                                formik.setFieldValue("image", event.currentTarget.files[0]);
                                            }} />


                                            <div className="text-danger">
                                                <ErrorMessage name="image" />
                                            </div>
                                        </div>





                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input type="submit" value="Edit Testimonial" className="btn btn-success " />
                            </div>
                        </div>

                    </Form>
                </FormikProvider>
            </section>

        </>
    )
}

export default EmailTemplateEdit;