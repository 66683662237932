import React, { useEffect, useState } from 'react';
import { FormikProvider, Field, Form, Formik, ErrorMessage, useFormik } from 'formik'
import * as yup from "yup";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { getSiteSetting, updateSiteSetting } from '../Services/service';
import { toast } from 'react-toastify';

const SiteSetting = () => {


  const [initialValues, setInitialValue] = useState({});

  useEffect(() => {

    const api_call = async () => {
      const response = await getSiteSetting();
      if (response.status === 200) {
        setInitialValue(response.data.data[0]);
      }
    }

    api_call();

  }, []);



  const validationCheck = yup.object().shape({
    // question: yup.string().required("Question is required"),
    // answer: yup.string().required("Answer is required")
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationCheck,
    onSubmit: async (values) => {
      try {
        values.id = '64620650a05ceca13d8ef16a';
        const response = await updateSiteSetting(values);
        if (response.status === 200) {
          toast(response.data.message);
        }
      } catch (error) {
        toast(error.response.data.message)
      }
    }
  });




  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>SiteSetting</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active">SiteSetting</li>
              </ol>
            </div>
          </div>
        </div>
      </section>


      <section className="content">



        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Detail</h3>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                        <i className="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="inputName">Title</label>
                      <Field
                        type="title"
                        name="title"
                        placeholder="Title"
                        className={`form-control`}
                      />


                      <div className="text-danger">
                        <ErrorMessage name="title" />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputName">H1</label>
                      <Field
                        type="H1"
                        name="H1"
                        placeholder="H1"
                        className={`form-control`}
                      />

                      <div className="text-danger">
                        <ErrorMessage name="H1" />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputDescription">H1 description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={initialValues.descriptionH1}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          formik.setFieldValue('descriptionH1', data);
                        }}
                      />
                      <div className="text-danger">
                        <ErrorMessage name="descriptionH1" />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputName">H2</label>
                      <Field
                        type="H2"
                        name="H2"
                        placeholder="H2"
                        className={`form-control`}
                      />

                      <div className="text-danger">
                        <ErrorMessage name="H2" />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputDescription">H2 description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={initialValues.descriptionH2}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          formik.setFieldValue('descriptionH2', data);
                        }}
                      />
                      <div className="text-danger">
                        <ErrorMessage name="descriptionH2" />
                      </div>
                    </div>


                    <div className="form-group">
                      <label htmlFor="inputName">H3</label>
                      <Field
                        type="H3"
                        name="H3"
                        placeholder="H3"
                        className={`form-control`}
                      />

                      <div className="text-danger">
                        <ErrorMessage name="H3" />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputDescription">H3 description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={initialValues.descriptionH3}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          formik.setFieldValue('descriptionH3', data);
                        }}
                      />
                      <div className="text-danger">
                        <ErrorMessage name="descriptionH3" />
                      </div>
                    </div>


                    <div className="form-group">
                      <label htmlFor="inputName">H4</label>
                      <Field
                        type="H4"
                        name="H4"
                        placeholder="H4"
                        className={`form-control`}
                      />

                      <div className="text-danger">
                        <ErrorMessage name="H4" />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputDescription">H4 description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={initialValues.descriptionH4}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          formik.setFieldValue('descriptionH4', data);
                        }}
                      />
                      <div className="text-danger">
                        <ErrorMessage name="descriptionH4" />
                      </div>
                    </div>



                  </div>
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-12">
                <input type="submit" value="Save" className="btn btn-success " />
              </div>
            </div>

          </Form>
        </FormikProvider>
      </section>

    </>
  )
}

export default SiteSetting;