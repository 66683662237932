import React, { useEffect, useState } from 'react';
import { FormikProvider, Field, Form, Formik, ErrorMessage, useFormik } from 'formik'
import * as yup from "yup";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    getAllReferral,
    getReferral,
    addReferral,
    updateReferral,
    deleteReferral,
} from '../../Services/service';
import { toast } from 'react-toastify';
import { useNavigate,useParams } from 'react-router-dom';
import { base } from '../../constant';



const ReferralEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [initialValues, setInitialValue] = useState({});



    useEffect(() => {

        const api_call = async () => {
            const response = await getReferral({ id: id });
            if (response.status === 200) {
                const {
                    code,
                    validity
                } = response.data.data;
                setInitialValue({
                    code,
                    validity: dateFormat(validity)
                })
            }
        }

        api_call();

    }, []);




    const validationCheck = yup.object().shape({
        code: yup.string().trim().max(255, 'Maximum 255 character allowed').required("Code is required"),
        validity: yup.string().trim().required("Image is required"),
    })
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: validationCheck,
        onSubmit: async (values) => {

            try {
                values.id = id;
                const response = await updateReferral(values);
                if (response.status === 200) {
                    navigate("/admin/referral");
                    toast(response.data.message);
                }
            } catch (error) {
                toast(error.response.data.message)
            }
        }
    });

    const dateFormat = (timestamp) => {
        const year = timestamp.slice(0, 4);
        const month = timestamp.slice(5, 7);
        const day = timestamp.slice(8, 10);

        // combine the year, month, and day into the desired format
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
    }


    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Edit Referral</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Edit Referral</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">



                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Detail</h3>

                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="inputName">Code</label>
                                            <Field
                                                type="name"
                                                name="code"
                                                placeholder="Code"
                                                className={`form-control`}

                                            />


                                            <div className="text-danger">
                                                <ErrorMessage name="code" />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="inputName">Validity</label>
                                           
                                            <Field
                                            type="date"
                                            name="validity"
                                            placeholder="Validity"
                                            className={`form-control`}
                                            />

                                            <div className="text-danger">
                                                <ErrorMessage name="name" />
                                             </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input type="submit" value="Edit Referral" className="btn btn-success " />
                            </div>
                        </div>

                    </Form>
                </FormikProvider>
            </section>

        </>
    )
}

export default ReferralEdit;