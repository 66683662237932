import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TagInput from "../../Component/TagInput";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import Select from "react-select";
import * as constant from "../../constant";
import axios from "axios";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { getDish } from '../../Services/service';

function EditDish() {


  const [dishImage, setDishImageURL] = useState("/images/dish-img.png");
  const [CookingImage, setCookingImageURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectWindowType, setWindowType] = useState("any_time");
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [optionsFood_type, setOptionsFood_type] = useState([]);
  const [dishData, setdishData] = useState({});
  const [dietaryTag, setDietaryTag] = useState([]);
  const [price, setPrice] = useState([{}]);
  const [ingredientPrice, setIngredientPrice] = useState("");
  const [ready_to_cookPrice, setReady_to_cookPrice] = useState("");
  const [ready_to_heatPrice, setReady_to_heatPrice] = useState("");
  const [ready_to_eatPrice, setReady_to_eatPrice] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  const diatry_tags_array = [
    { label: "Dairy Free", value: "Dairy Free" },
    { label: "Allergy Friendly", value: "Allergy Friendly" },
    { label: "Allergy Friendly", value: "Allergy Friendly" },
    { label: "Gluten Free", value: "Gluten Free" },
    { label: "Halal", value: "Halal" },
    { label: "Keto", value: "Keto" },
    { label: "Kosher", value: "Kosher" },
    { label: "Low Carb", value: "Low Carb" },
    { label: "Low FODMAP", value: "Low FODMAP" },
    { label: "Low Sodium", value: "Low Sodium" },
    { label: "Muscle Building", value: "Muscle Building" },
    { label: "Organic", value: "Organic" },
    { label: "Paleo", value: "Paleo" },
    { label: "Pescatarian", value: "Pescatarian" },
    { label: "Vegetarian", value: "Vegetarian" },
    { label: "Vegan", value: "Vegan" },
    { label: "Weight Loss", value: "Weight Loss" },
    { label: "Whole 30", value: "Whole 30" },
  ];

  useEffect(() => {
    const fetchDataCouisin = async () => {
      try {
        let token = localStorage.getItem("token");
        const headers = { Authorization: "Bearer " + token };
        const response = await axios.get(constant.dashBoardUrl + "/getAllCuisine", { headers });

        let couisin_data = response.data.data.map((item, index) => {
          item.label = item.name;
          item.value = item._id;
          return item;
        });

        setOptions(couisin_data);

        
      } catch (error) {
        console.error("Error:", error.message);
      }
    };
    const fetchDataFood_type = async () => {
      try {
        let token = localStorage.getItem("token");
        const headers = { Authorization: "Bearer " + token };
        const response = await axios.get(constant.dashBoardUrl + "/getAllFoodType", { headers });

        let food_type_data = response.data.data.map((item, index) => {
          item.label = item.name;
          item.value = item._id;
          return item;
        });

        setOptionsFood_type(food_type_data);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    const fetchDataDish = async () => {
      try {
        setIsLoading(true);
        let token = localStorage.getItem("token");
        const headers = { Authorization: "Bearer " + token };

     

  
        
        const response =  await getDish({ id: id });


        setdishData(response.data.data);
        setSelectedOption(
          response.data.data.cuisine.map((item, index) => {
            return { label: item.name, value: item._id };
          })
        );
        // setOptionsFood_type(response.data.data.food_type);
        setPrice(response.data.data.food_class);
        setDishImageURL(constant.base +"/"+ response.data.data.dish_image);

        if (response.data.data.cooking_pdf) {
          setCookingImageURL("/images/pdf.png");
        }

        setDietaryTag(
          response.data.data.dietary_tag.map((item, index) => {
            return { label: item, value: item };
          })
        );


        const newFoodClass = response.data.data?.food_class?.map((item, index) => {
          if (item.class === "Ingredients") {
            return setIngredientPrice(item.price);
          } else if (item.class === "Ready to Cook") {
            return setReady_to_cookPrice(item.price);
          } else if (item.class === "Ready to Heat") {
            return setReady_to_heatPrice(item.price);
          } else if (item.class === "Ready to Eat") {
            return setReady_to_eatPrice(item.price);
          } else {
            return "";
          }
        });
        

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error:", error.message);
      }
    };

    fetchDataDish();
    fetchDataCouisin();
    fetchDataFood_type();
  }, []);

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, " Dish Name must be at least 3 characters ")
      .max(55, " Dish Name must be less than 55 characters ")
      .required("Dish Name is  required")
      .matches(/^[A-Za-z ]*$/, "Dish name is not valid"),
    spice_level: Yup.string().required("Spice Level is required"),
    preparation_time: Yup.string()
      .required("Preparation Time is required ")
      .matches(/^\d+$/, "Enter valid Preparation Time")

      .test("maxTwoDigits", "Preparation Time should not be more than 120 minutes", (number) => Number(number) <= 120),
    dish_image: Yup.mixed().required("Dish Image is required"),
    description: Yup.string()
      .required("Description is required")
      .min(5, "Description must be at least 5 characters ")
      .max(500, "Description must be less than 500 characters "),
    recipe: Yup.string().required("Recipe is required"),

    ingredients_price: Yup.boolean(),
    ready_to_cook_price: Yup.boolean(),
    ready_to_heat_price: Yup.boolean(),
    ready_to_eat_price: Yup.boolean(),

    set_from_time: Yup.boolean(),
    set_to_time: Yup.boolean(),

    set_week_day: Yup.boolean(),

    Ingredients: Yup.string().when("ingredients_price", {
      is: true, // alternatively: (val) => val == true
      then: (schema) =>
        schema
          .required("Ingredients price is required")
          .matches(/^\d+(?:\.\d{1,3})?$/, "Enter valid Price")
          .test("maxTwoDigits", "price should not be more than $500", (number) => Number(number) <= 500),
      otherwise: (schema) => schema.min(0),
    }),
    "Ready to Cook": Yup.string().when("ready_to_cook_price", {
      is: true, // alternatively: (val) => val == true
      then: (schema) =>
        schema
          .required("Ready to Cook price is required")
          .matches(/^\d+(?:\.\d{1,3})?$/, "Enter valid Price")
          .test("maxTwoDigits", "price should not be more than $500", (number) => Number(number) <= 500),
      otherwise: (schema) => schema.min(0),
    }),
    "Ready to Heat": Yup.string().when("ready_to_heat_price", {
      is: true, // alternatively: (val) => val == true
      then: (schema) =>
        schema
          .required("Ready to Heat price is required")
          .matches(/^\d+(?:\.\d{1,3})?$/, "Enter valid Price")
          .test("maxTwoDigits", "price should not be more than $500", (number) => Number(number) <= 500),
      otherwise: (schema) => schema.min(0),
    }),
    "Ready to Eat": Yup.string().when("ready_to_eat_price", {
      is: true, // alternatively: (val) => val == true
      then: (schema) =>
        schema
          .required("Ready to Eat price is required")
          .matches(/^\d+(?:\.\d{1,3})?$/, "Enter valid Price")
          .test("maxTwoDigits", "price should not be more than $500", (number) => Number(number) <= 500),
      otherwise: (schema) => schema.min(0),
    }),

    food_class: Yup.array().min(1, "Class of food is required"),

    from_time: Yup.string().when("set_from_time", {
      is: true,
      then: (schema) => schema.required("Time is required"),
      otherwise: (schema) => schema.min(0),
    }),
    to_time: Yup.string().when("set_to_time", {
      is: true,
      then: (schema) => schema.required("Time is required"),
      otherwise: (schema) => schema.min(0),
    }),

    product_label: Yup.array().min(1, "Product Label is required"),
    food_type: Yup.array().min(1, "Food Type is required"),
    price: Yup.array().min(1, "Please add Price"),
    cuisine: Yup.array().min(1, "Please add cuisine"),
    dietary_tag: Yup.array().min(1, "Please add dietary tag"),
    window_type: Yup.string().min(1, "Please add window type"),
    faq: Yup.string().matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
      "Not valid link"
    ),
    food_type: Yup.array()
      .of(Yup.string().required("Food type is required"))
      .required("Must have Food Type")
      .min(1, "Minimum of 1 Food Type"),
    ingredients: Yup.array()
      .of(Yup.string().required("Ingredients is required"))
      .required("Must have Ingredients")
      .min(1, "Minimum of 1 Ingredients"),

    toppings: Yup.array()
      .of(Yup.string().required("Dish Option is required"))
      .required("Must have Dish Option")
      .min(1, "Minimum of 1 Dish Option"),
   
  });

  const handleTime = (e, setFieldValue) => {
    let z = e.target.value;
    let zipReg = /^\d+$/;
    if (zipReg.test(z) || z === "") {
      if (z.length <= 3) {
        setFieldValue("preparation_time", z);
      }
    }
  };
  const swapElements = (arr, x, y) => {
    [arr[x], arr[y]] = [arr[y], arr[x]];
    return arr;
  };

  return (
    <div class="dash-side-R-body">
      {isLoading ? (
        <p>loading..</p>
      ) : (
        <div class="row">
          <div class="col-md-8 m-auto">
            <div class="form-box signup-form col-md-8 m-auto">
              <div class="row">
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: dishData.name || "",
                    dish_image: dishData.dish_image || "",
                    recurring: dishData.recurring || 0,
                    status: dishData.status || 1,
                    product_label: dishData.label || [],
                    cuisine: dishData?.cuisine || [],
                    spice_level: dishData.spice_level || "",
                    food_type: dishData?.food_type?.map((type) => type._id) || [],
                    preparation_time: dishData.preparation_time || "",
                    description: dishData.description || "",
                    recipe: dishData.recipe || "",
                    from_time: "",
                    set_from_time: false,
                    set_to_time: false,
                    to_time: "",
                    not_include: dishData.not_included || [],
                    utensils: dishData.utensils || [],
                    instructions: dishData.instructions || "",
                    toppings: dishData?.toppings?.map((type) => type) || [],
                    ingredients: dishData?.ingredients?.map((ingredient) => ingredient.name) || [],
                    class_ingredients: dishData?.class_ingredients || [],
                    food_class: dishData.food_class || [],
                    portion: dishData.portion || "",
                    allergens: dishData.allergens || "",
                    nutitional: dishData.nutitional || "",
                    faq: dishData.faq || "",
                    window_type: "any_time",
                    week_day: [""],
                    set_week_day: false,
                    Ingredients: ingredientPrice || "",
                    "Ready to Cook": ready_to_cookPrice || "",
                    "Ready to Heat": ready_to_heatPrice || "",
                    "Ready to Eat": ready_to_eatPrice || "",
                    ingredients_price: dishData?.food_class?.some((cls) => cls.class === "Ingredients"),
                    ready_to_cook_price: dishData?.food_class?.some((cls) => cls.class === "Ready to Cook"),
                    ready_to_heat_price: dishData?.food_class?.some((cls) => cls.class === "Ready to Heat"),
                    ready_to_eat_price: dishData?.food_class?.some((cls) => cls.class === "Ready to Eat"),
                 
                    dietary_tag: dishData.dietary_tag || [],
                    cooking_pdf: dishData.cooking_pdf || "",
                    dish_option: dishData.dish_option || "single"
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={async (values) => {
                    let formData = new FormData();
                    formData.append("name", values.name);
                    formData.append("recurring", values.recurring);
                    formData.append("status", values.status);
                    formData.append("label", JSON.stringify(values.product_label));
                    formData.append("cuisine", JSON.stringify(values.cuisine));
                    formData.append("food_class", JSON.stringify(values.food_class));
                    formData.append("spice_level", values.spice_level);
                    formData.append("food_type", JSON.stringify(values.food_type));
                    formData.append("preparation_time", values.preparation_time);
                    formData.append("description", values.description);
                    formData.append("ingredients", JSON.stringify(values.ingredients));
                    formData.append("class_ingredients", JSON.stringify(values.class_ingredients));
                    formData.append("dish_image", values.dish_image);
                    formData.append("cooking_pdf", values.cooking_pdf);
                    formData.append("dish_option", values.dish_option);
                    formData.append("portion", values.portion);
                    formData.append("allergens", values.allergens);
                    formData.append("nutitional", values.nutitional);
                    formData.append("not_included", JSON.stringify(values.not_include));
                    formData.append("utensils", JSON.stringify(values.utensils));
                    formData.append("instructions", values.instructions);
                    formData.append("recipe", values.recipe);
                    formData.append("faq", values.faq);
                    formData.append("toppings", JSON.stringify(values.toppings));
                    formData.append("dietary_tag", JSON.stringify(values.dietary_tag));
               

                    try {
                      let token = localStorage.getItem("token");
                      const headers = { Authorization: "Bearer " + token };
                      const response = await axios.put(constant.dashBoardUrl + "/updateDish", formData, { params: { id: id }, headers });
                      toast.success("Dish Update successfully!", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                      navigate("/admin/dish");
                    } catch (error) {
                      toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    }
                  }}>
                  {({ errors, touched, handleChange, handleBlur, values, isSubmitting, setFieldValue, setFieldError }) => (
                    <Form>
                      <ToastContainer />
                      <div class="col-md-12" style={{ marginBottom: "30px" }}>
                        <div class="upload-photo">
                          <div class="avatar-upload">
                            <div class="avatar-edit">
                              <input
                                id="imageUpload"
                                type="file"
                                name="dish_image"
                                accept=".png, .jpg, .jpeg"
                                onChange={(event) => {
                                  if (
                                    event.target.files[0].type == "image/png" ||
                                    event.target.files[0].type == "image/jpg" ||
                                    event.target.files[0].type == "image/jpeg"
                                  ) {
                                    if (event.target.files[0].size <= 1 * 1024 * 1024) {
                                      setFieldValue("dish_image", event.currentTarget.files[0]);
                                      let image_url = URL.createObjectURL(event.currentTarget.files[0]);
                                      setDishImageURL(image_url);
                                    } else {
                                      toast.error("Size must be less than 1 MB", {
                                        position: toast.POSITION.TOP_RIGHT,
                                      });
                                      setFieldError("dish_image", "Size must be less than 1 MB");
                                    }
                                  } else {
                                    toast.error("Invalid File Format", {
                                      position: toast.POSITION.TOP_RIGHT,
                                    });
                                    setFieldError("dish_image", "Invalid File Format");
                                  }
                                }}
                              />

                              <label for="imageUpload" class="img-upload-icon">
                                <i class="ri-edit-box-line"></i>
                              </label>
                            </div>
                            <div class="avatar-preview">
                              <div id="imagePreview" class="avatar-preview-img">
                                <img src={dishImage && dishImage} />
                              </div>
                            </div>
                          </div>
                          <span class="validation-error" style={{ display: "flex" }}>
                            {errors.dish_image && touched.dish_image && errors.dish_image}
                          </span>
                        </div>
                      </div>
                      <div class="form-group mb-4 col-md-12">
                        <label class="form-label">Dish Name</label>

                        <input
                          type="text"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          placeholder="Enter Dish name"
                          class="form-control"
                        />
                        <span class="validation-error">{errors.name && touched.name && errors.name}</span>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group mb-4 d-flex justify-content-between">
                          <label class="form-label">Available As Recurring?</label>
                          <label class="switch-custom md-swich">
                            <input
                              type="checkbox"
                              name="recurring"
                              defaultChecked={values.recurring}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.recurring}
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group mb-4 d-flex justify-content-between">
                          <label class="form-label">Enabled/Disabled</label>
                          <label class="switch-custom md-swich">
                            <input
                              type="checkbox"
                              name="status"
                              defaultChecked={values.status}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.status}
                            />

                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div class="form-group mb-4 col-md-12 ">
                        <div class="label-box">
                          <label class="form-label">Product Label </label>
                        </div>
                        <div class="tag-input-box">
                          <div class="input-box">
                            

                            <TagInput
                              placeholder="Enter Product Label"
                              initialData={values.product_label}
                              get_tags={(values) => {
                                setFieldValue("product_label", values);
                              }}
                            />
                            <span class="validation-error">{errors.product_label && touched.product_label && errors.product_label}</span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-4 col-md-12">
                        <label class="form-label">Cuisine</label>

                        <Select
                          defaultValue={selectedOption}
                          isMulti
                          onChange={(value) => {
                            setFieldValue(
                              "cuisine",
                              value.map((item) => {
                                return item.value;
                              })
                            );
                          }}
                          options={options}
                        />
                        <span class="validation-error">{errors.cuisine && touched.cuisine && errors.cuisine}</span>
                      </div>
                      <div class="form-group mb-4 col-md-12">
                        <label class="form-label">Class Of Food</label>
                        <ul class="box-check-box-list d-flex">
                          <li class="me-4">
                            <label class="coustom-checkbox box-check-box">
                              <input
                                type="checkbox"
                                checked={values.food_class.some((cls) => cls.class === "Ingredients")}
                                onChange={(e) => {
                                  let { value, checked } = e.target;
                                  if (checked) {
                                    setFieldValue("ingredients_price", true);
                                    setFieldValue("food_class", [
                                      ...values.food_class,
                                      {
                                        class: "Ingredients",
                                        price: "",
                                      },
                                    ]);
                                  } else {
                                    setFieldValue("ingredients_price", false);
                                    setFieldValue(
                                      "food_class",
                                      values.food_class.filter((e) => e.class !== "Ingredients")
                                    );
                                  }
                                }}
                              />

                              {console.log("my food classes", values.food_class)}
                              <span class="checkmark"></span>
                              <div class="content-check">
                                <figure>
                                  <img src="/images/food-icon-1.png" />
                                </figure>
                                <h4>Ingredients</h4>
                              </div>
                            </label>
                          </li>
                          <li class="me-4">
                            <label class="coustom-checkbox box-check-box">
                              <input
                                type="checkbox"
                                checked={values.food_class.some((cls) => cls.class === "Ready to Cook")}
                                onChange={(e) => {
                                  let { value, checked } = e.target;
                                  if (checked) {
                                    setFieldValue("ready_to_cook_price", true);
                                    setFieldValue("food_class", [
                                      ...values.food_class,
                                      {
                                        class: "Ready to Cook",
                                        price: "",
                                      },
                                    ]);
                                  } else {
                                    setFieldValue("ready_to_cook_price", false);
                                    setFieldValue(
                                      "food_class",
                                      values.food_class.filter((e) => e.class !== "Ready to Cook")
                                    );
                                  }
                                }}
                              />
                              <span class="checkmark"></span>
                              <div class="content-check">
                                <figure>
                                  <img src="/images/food-icon-2.png" />
                                </figure>
                                <h4>Ready to Cook</h4>
                              </div>
                            </label>
                          </li>
                          <li class="me-4">
                            <label class="coustom-checkbox box-check-box">
                              <input
                                type="checkbox"
                                checked={values.food_class.some((cls) => cls.class === "Ready to Heat")}
                                onChange={(e) => {
                                  let { value, checked } = e.target;
                                  if (checked) {
                                    setFieldValue("ready_to_heat_price", true);
                                    setFieldValue("food_class", [
                                      ...values.food_class,
                                      {
                                        class: "Ready to Heat",
                                        price: "",
                                      },
                                    ]);
                                  } else {
                                    setFieldValue("ready_to_heat_price", false);
                                    setFieldValue(
                                      "food_class",
                                      values.food_class.filter((e) => e.class !== "Ready to Heat")
                                    );
                                  }
                                }}
                              />
                              <span class="checkmark"></span>
                              <div class="content-check">
                                <figure>
                                  <img src="/images/food-icon-3.png" />
                                </figure>
                                <h4>Ready to Heat</h4>
                              </div>
                            </label>
                          </li>
                          <li>
                            <label class="coustom-checkbox box-check-box">
                              <input
                                type="checkbox"
                                checked={values.food_class.some((cls) => cls.class === "Ready to Eat")}
                                onChange={(e) => {
                                  let { value, checked } = e.target;
                                  if (checked) {
                                    setFieldValue("ready_to_eat_price", true);
                                    setFieldValue("food_class", [
                                      ...values.food_class,
                                      {
                                        class: "Ready to Eat",
                                        price: "",
                                      },
                                    ]);
                                  } else {
                                    setFieldValue("ready_to_eat_price", false);
                                    setFieldValue(
                                      "food_class",
                                      values.food_class.filter((e) => e.class !== "Ready to Eat")
                                    );
                                  }
                                }}
                              />
                              <span class="checkmark"></span>
                              <div class="content-check">
                                <figure>
                                  <img src="/images/food-icon-4.png" />
                                </figure>
                                <h4>Ready to Eat</h4>
                              </div>
                            </label>
                          </li>
                        </ul>

                        <span class="validation-error">{errors.food_class && touched.food_class && errors.food_class}</span>
                      </div>
                      <div class="form-group mb-4 col-md-6">
                        <label class="form-label">Spice Level</label>
                        <select
                          name="spice_level"
                          value={values.spice_level}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ display: "block" }}
                          class="form-select">
                          <option value=""> Select Spice level</option>
                          <option value="Not spicy" label="Not spicy">
                            Not spicy
                          </option>
                          <option value="Medium" label="Medium">
                            Medium
                          </option>
                          <option value="Mild" label="Mild">
                            Mild
                          </option>
                          <option value="hot" label="hot">
                            hot
                          </option>
                        </select>

                        <span class="validation-error">{errors.spice_level && touched.spice_level && errors.spice_level}</span>
                      </div>
                      <div class="col-md-12">
                        <hr class="divider-accordion" />
                      </div>
                      <div class="form-group mb-4 col-md-12">
                        <label class="form-label">Price</label>
                        <p class="mb-3">
                          You earn $x from each dish sold! Eats collects $y (z%) to cover marketing, operations, customer support and
                          software development costs."
                        </p>
                        <div class="row row-cols-2">
                          {values.food_class.map((item, index) => {
                            return (
                              <div class="cols" key={index}>
                                <input
                                  type="text"
                                  name={item.class}
                                  placeholder={`Enter Price for ${item.class}`}
                                  class="form-control price_box"
                                  value={item.price}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue(`food_class.${index}.price`, e.target.value);
                                    setFieldValue(`${item.class}`, e.target.value);
                                  }}
                                />
                             <span class="validation-error">{errors[item.class]}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div class="col-md-12">
                        <hr class="divider-accordion" />
                      </div>
                      <div class="form-group mb-4 col-md-12">
                        <label class="form-label">Food Type</label>
                        {console.log("-66---", values.food_type)}
                        <div class="mb-3">
                          <FieldArray
                            name="food_type"
                            render={(arrayHelpers) => (
                              <div>
                                {values.food_type.map((food_type, index) => (
                                  <div key={index}>
                                    <div class="input-add-box d-flex justify-content-between align-items-center">
                                      <Field as="select" name={`food_type.${index}`} placeholder="Enter Food Type" class="form-control">
                                        <option value="">Select Food Type</option>
                                        {optionsFood_type.map((food) => (
                                          <option key={food._id} value={food._id}>
                                            {food.name}
                                          </option>
                                        ))}
                                      </Field>

                                      {index !== 0 && (
                                        <a class="action-box delete-action ms-3" onClick={() => arrayHelpers.remove(index)}>
                                          <i class="ri-delete-bin-line"></i>
                                        </a>
                                      )}
                                    </div>

                                    <div class="validation-error">
                                      <ErrorMessage name={`food_type.${index}`} />
                                    </div>
                                  </div>
                                ))}

                                <div class="add-more d-flex align-items-center mt-3">
                                  <a class="action-box me-2" onClick={() => arrayHelpers.push("")}>
                                    <i class="ri-add-fill"></i>
                                  </a>
                                  Add more
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>

                      <div class="form-group mb-4 col-md-6">
                        <label class="form-label">Preparation Time In Minutes</label>

                        <input
                          type="text"
                          name="preparation_time"
                          onChange={(e) => handleTime(e, setFieldValue)}
                          onBlur={handleBlur}
                          value={values.preparation_time}
                          placeholder="Enter Preparation time"
                          class="form-control"
                        />

                        <span class="validation-error">
                          {errors.preparation_time && touched.preparation_time && errors.preparation_time}
                        </span>
                      </div>
                      <div class="form-group mb-4 col-md-12">
                        <label class="form-label">Description</label>

                        <textarea
                          name="description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description.replace(/ +/g, " ")}
                          placeholder="Enter Description"
                          class="form-control note"
                        />

                        <span class="validation-error">{errors.description && touched.description && errors.description}</span>
                      </div>
                      <h4 class="inner-title mb-3">All Details (Optional)</h4>
                      <div class="form-group mb-4 col-md-6">
                        <label class="form-label">Allergens</label>
                        <input
                          type="text"
                          name="allergens"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Allergens"
                          value={values.allergens.replace(/ +/g, " ")}
                          class="form-control"
                        />
                      </div>
                      <div class="col-md-12">
                        <div class="row">
                          <div class="form-group mb-4 col-md-6">
                            <label class="form-label">Nutritional</label>
                            <input
                              type="text"
                              name="nutitional"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.nutitional.replace(/ +/g, " ")}
                              placeholder="Enter Nutritional"
                              class="form-control"
                            />
                          </div>
                          <div class="form-group mb-4 col-md-6">
                            <label class="form-label">Portions</label>
                            <input
                              type="text"
                              name="portion"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.portion.replace(/ +/g, " ")}
                              placeholder="Enter Portions"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-4 col-md-12 ">
                        <div class="label-box d-flex justify-content-between">
                          <label class="form-label">Not Included</label>
                          <span class="tool-tip-box con-tooltip top" />
                        </div>
                        <div class="tag-input-box">
                          <div class="input-box">
                            <TagInput
                              initialData={values.not_include || []}
                              placeholder="Enter Not Included"
                              get_tags={(values) => {
                                setFieldValue("not_include", values);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-4 col-md-12 ">
                        <div class="label-box d-flex justify-content-between">
                          <label class="form-label">Utensils Included</label>
                          <span class="tool-tip-box con-tooltip top" />
                        </div>

                        <div class="tag-input-box">
                          <div class="input-box">
                            <TagInput
                              initialData={values.utensils || []}
                              placeholder="Enter Utensils Included"
                              get_tags={(values) => {
                                setFieldValue("utensils", values);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-4 col-md-12">
                        <label class="form-label">Cooking Instructions</label>
                        <div class="upload-textarea">
                          <textarea
                            name="instructions"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.instructions.replace(/ +/g, " ")}
                            placeholder="Enter Cooking Instructions"
                            class="form-control note"></textarea>
                          <div class="upload-btn-wrapper">
                            <button class="btn">
                              <i class="ri-upload-line"></i> Upload PDF
                            </button>
                            <input
                              id="imageUpload"
                              type="file"
                              name="cooking_pdf"
                              onChange={(event) => {
                                if (event.target.files[0].type == "application/pdf") {
                                  if (event.target.files[0].size <= 5 * 1024 * 1024) {
                                    setFieldValue("cooking_pdf", event.currentTarget.files[0]);
                                    setCookingImageURL("/images/pdf.png");
                                  } else {
                                    toast.error("Size must be less than 5 MB", {
                                      position: toast.POSITION.TOP_RIGHT,
                                    });
                                    setFieldError("cooking_pdf", "Size must be less than 5 MB");
                                    setCookingImageURL("");
                                  }
                                } else {
                                  toast.error("Invalid File Format", {
                                    position: toast.POSITION.TOP_RIGHT,
                                  });
                                  setFieldError("cooking_pdf", "Invalid File Format");
                                  setCookingImageURL("");
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div class="avatar-preview">
                          {CookingImage && (
                            <div id="imagePreview" class="avatar-preview-img" style={{ width: "72px" }}>
                              <span
                                onClick={() => {
                                  setCookingImageURL("");
                                  setFieldValue("cooking_pdf", "");
                                }}>
                                x
                              </span>
                              <img src={CookingImage && CookingImage} />
                            </div>
                          )}

                          <span class="validation-error">{errors.cooking_pdf && touched.cooking_pdf && errors.cooking_pdf}</span>
                        </div>
                      </div>
                      <div class="form-group mb-4 col-md-12">
                        <label class="form-label">Recipe</label>

                        <textarea
                          name="recipe"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.recipe.replace(/ +/g, " ")}
                          placeholder="Enter Recipe"
                          class="form-control note"
                        />

                        <span class="validation-error">{errors.recipe && touched.recipe && errors.recipe}</span>
                      </div>
                      <div class="form-group mb-4 col-md-6">
                        <label class="form-label">FAQ - Link To An Offsite FAQ</label>
                        <input
                          type="text"
                          name="faq"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter FAQ Link"
                          value={values.faq.replace(/ +/g, " ")}
                          class="form-control"
                        />
                        <span class="validation-error">{errors.faq && touched.faq && errors.faq}</span>
                      </div>
                      <div class="form-group mb-4 col-md-12 mb-4">
                        <div class="label-box d-flex justify-content-between">
                          <label class="form-label">Dish Options/ Toppings</label>
                          <span class="tool-tip-box con-tooltip top">
                            <i class="ri-information-line"></i>
                            <div class="tooltip ">
                              <p>Top</p>
                            </div>
                          </span>
                        </div>
                        <div class="custom-check-label d-flex mb-3">
                          

                        <label class="radio-coustom-design me-4 mb-0">
                            <input
                              type="radio"
                              name="dish_option"
                              value="single"
                              onChange={handleChange}
                              checked={values.dish_option === "single"}
                            />
                            <span class="checkmark"></span>
                            <p>Single Select</p>
                          </label>


                          <label class="radio-coustom-design me-4 mb-0">
                            <input
                              type="radio"
                              name="dish_option"
                              value="multiple"
                              onChange={handleChange}
                              checked={values.dish_option === "multiple"}
                            />
                            <span class="checkmark"></span>
                            <p>Multi Select</p>
                          </label>



                        </div>
                        <div class="form-group mb-4 col-md-12">
                          <FieldArray
                            name="toppings"
                            render={(arrayHelpers) => (
                              <div>
                                {values.toppings.map((toppings, index) => (
                                  <div key={index}>
                                    <div class="input-add-box d-flex justify-content-between align-items-center">
                                      <Field
                                        name={`toppings.${index}`}
                                        placeholder="Enter Dish Option"
                                        class="form-control"
                                        value={toppings}
                                      />
                                      {index !== 0 && (
                                        <>
                                          <a
                                            class="action-box"
                                            onClick={() => {
                                              setFieldValue("toppings", swapElements(values.toppings, index - 1, index));
                                            }}>
                                            <i class="ri-arrow-up-down-line"></i>
                                          </a>
                                          <a class="action-box delete-action ms-3" onClick={() => arrayHelpers.remove(index)}>
                                            <i class="ri-delete-bin-line"></i>
                                          </a>
                                        </>
                                      )}
                                    </div>
                                    <div class="validation-error">
                                      <ErrorMessage name={`toppings.${index}`} />
                                    </div>
                                  </div>
                                ))}

                                <div class="add-more d-flex align-items-center mt-3">
                                  <a class="action-box me-2" onClick={() => arrayHelpers.push("")}>
                                    <i class="ri-add-fill"></i>
                                  </a>
                                  Add more
                                </div>
                              </div>
                            )}
                          />
                        </div>

                        <div class="form-group mb-4 col-md-12">
                          <label class="form-label">Ingredients</label>

                          <FieldArray
                            name="ingredients"
                            render={(arrayHelpers) => (
                              <div>
                                {values.ingredients.map((Ingredient, index) => (
                                  <div key={index}>
                                    <div class="input-add-box d-flex justify-content-between align-items-center" key={index}>
                                      <Field name={`ingredients.${index}`} placeholder="Enter Ingredients" class="form-control" />
                                      {index !== 0 && (
                                        <>
                                          <a
                                            class="action-box"
                                            onClick={() => {
                                              setFieldValue("ingredients", swapElements(values.ingredients, index - 1, index));
                                            }}>
                                            <i class="ri-arrow-up-down-line"></i>
                                          </a>
                                          <a class="action-box delete-action ms-3" onClick={() => arrayHelpers.remove(index)}>
                                            <i class="ri-delete-bin-line"></i>
                                          </a>
                                        </>
                                      )}
                                    </div>
                                    <span class="validation-error">
                                      <ErrorMessage name={`ingredients.${index}`} />
                                    </span>
                                  </div>
                                ))}

                                <div class="add-more d-flex align-items-center mt-3">
                                  <a class="action-box  me-2" onClick={() => arrayHelpers.push("")}>
                                    <i class="ri-add-fill"></i>
                                  </a>
                                  Add more
                                </div>
                              </div>
                            )}
                          />
                        </div>
                        <div class="form-group mb-4 col-md-12">
                          <label class="form-label">Ingredients (For Ingredients Class)</label>

                          <FieldArray
                            name="class_ingredients"
                            render={(arrayHelpers) => (
                              <div>
                                {values.class_ingredients.map((class_ingredients, index) => (
                                  <div class="input-add-box d-flex justify-content-between align-items-center" key={index}>
                                    <Field name={`class_ingredients.${index}`} placeholder="Enter Ingredients" class="form-control" />
                                    {index !== 0 && (
                                      <>
                                        <a
                                          class="action-box"
                                          onClick={() => {
                                            setFieldValue("class_ingredients", swapElements(values.class_ingredients, index - 1, index));
                                          }}>
                                          <i class="ri-arrow-up-down-line"></i>
                                        </a>
                                        <a class="action-box delete-action ms-3" onClick={() => arrayHelpers.remove(index)}>
                                          <i class="ri-delete-bin-line"></i>
                                        </a>
                                      </>
                                    )}
                                  </div>
                                ))}

                                <div class="add-more d-flex align-items-center mt-3">
                                  <a class="action-box  me-2" onClick={() => arrayHelpers.push("")}>
                                    <i class="ri-add-fill"></i>
                                  </a>
                                  Add more
                                </div>
                              </div>
                            )}
                          />
                        </div>
                        <div class="form-group mb-4 col-md-6">
                          <label class="form-label">Dietary Tags</label>

                          <Select
                            defaultValue={[
                              { label: "Dairy Free", value: "Dairy Free" },
                              { label: "Allergy Friendly", value: "Allergy Friendly" },
                            ]}
                            isMulti
                            onChange={(value) => {
                              setFieldValue(
                                "dietary_tag",
                                value.map((item) => {
                                  return item.value;
                                })
                              );
                            }}
                            options={diatry_tags_array}
                          />

                          <span class="validation-error">{errors.dietary_tag && touched.dietary_tag && errors.dietary_tag}</span>
                        </div>
                        {/* <div class="form-group mb-4 col-md-12 bg-grey-box">
                        <label class="form-label">Set Communities Name</label>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="input-add-box mb-3">
                              <input
                                type="text"
                                name=""
                                placeholder="Enter Set Communities Name 1"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="input-add-box mb-3">
                              <input
                                type="text"
                                name=""
                                placeholder="Enter Set Communities Name 1"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="input-add-box ">
                              <input
                                type="text"
                                name=""
                                placeholder="Enter Set Communities Name 1"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="col-md-6 d-flex">
                            <div class="input-add-box ">
                              <input
                                type="text"
                                name=""
                                placeholder="Enter Set Communities Name 1"
                                class="form-control"
                              />
                            </div>
                            <div class="action-main ms-3">
                              <a class="action-box delete-action ms-2">
                                <i class="ri-delete-bin-line"></i>
                              </a>
                            </div>
                          </div>
                          <div class="add-more d-flex align-items-center mt-3">
                            <a class="action-box  me-2">
                              <i class="ri-add-fill"></i>
                            </a>
                            Add more
                          </div>
                        </div>
                      </div> */}
                        <div class="col-md-12">
                          <hr class="divider-accordion" />
                        </div>
                       
                      
                        <div class="col-md-12">
                         
                       
                          <div class="btn-group-box mt-5 d-flex justify-content-center">
                            <button type="submit" disabled={isSubmitting} class="btn btn-primary">
                              {" "}
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditDish;
