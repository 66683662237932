import React, { useState } from "react";
const TagInput = ({ get_tags, initialData=[], placeholder }) => {
  const [tags, setTags] =useState(initialData);


  const addTags = (event) => {
    const tagExists = tags.includes(event.target.value.replace(/\s+/g, ' ').trim().toLowerCase());
    if (event.key === "Enter" && event.target.value.trim() !== "") {
      if (!tagExists) {
        setTags([...tags, event.target.value.replace(/\s+/g, ' ').trim().toLowerCase()]);
        get_tags([...tags, event.target.value.replace(/\s+/g, ' ').trim().toLowerCase()]);
        event.target.value = "";
      }
    }
    event.preventDefault();
  };
  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
    get_tags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };



  return (
    <>
      <input type="text" class="form-control" onKeyUp={(event) => addTags(event)} placeholder={placeholder} />
      {tags.length  ? (
        <div className="tags-input-wrapper">
          {tags.map((tag, index) => (
            <span key={index} class="tag">
              {tag}
              <a onClick={() => removeTags(index)}>×</a>
            </span>
          ))}
        </div>
      ) : null}
    </>
  );
};
export default TagInput;
