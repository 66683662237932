import React, { useState } from "react";

const MainContext = React.createContext();

const MainContextProvider = (props) => {
    const tok = localStorage.getItem("token")
    const [token, setToken] = useState(tok)
    return (
        <MainContext.Provider value={{ token, setToken }}>
            {props.children}
        </MainContext.Provider>
    );
};

export { MainContextProvider, MainContext };