
import React, { useEffect, useState } from 'react'
import { getUser } from '../../Services/service';
import { useParams } from 'react-router-dom';

function UserView() {
    const { id } = useParams();
    const [user, setUser] = useState({});
    useEffect(() => {
        const api_call = async () => {
            const response = await getUser({ id: id });
            if (response.status === 200) {
                const userData = response.data.data;
                setUser(userData);
            }
        }
        api_call();
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-md-6 margin-30">
                    <div className="card card-primary">
                        <div className="card-header">
                            <h3 className="card-title">Detail</h3>

                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>

                          

                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="inputName">User name</label>
                                <div>
                                    <NullWrite value={user.first_name} placeholder="User Name" />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputName"> USER ID</label>
                                <div>
                                    <NullWrite value={user._id} placeholder="USER ID" />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputName">Email</label>
                                <div>
                                    <NullWrite value={user.email} placeholder="Email" />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputName">Phone number</label>
                                <div>
                                    <NullWrite value={user.phone} placeholder="Phone Number" />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputName">Membership detail</label>
                                <div>
                                    <NullWrite value="0"  />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputName">Number of orders placed</label>
                                <div>
                                    <NullWrite value="0"  />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default UserView


const NullWrite = ({ value, placeholder }) => {
    return !value ? (
        <span className='text-danger'>
            {placeholder} not defined
        </span>
    ) : value;
}