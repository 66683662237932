import React, { useEffect, useState } from 'react';
import { FormikProvider, Field, Form, Formik, ErrorMessage, useFormik } from 'formik'
import * as yup from "yup";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { getIngredients, updateIngredients } from '../../Services/service';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';


const IngredientsEdit = () => { 
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialValues, setInitialValue] = useState({
    name: "",
  });



  useEffect(() => {

    const api_call = async () => {
      const response = await getIngredients({ id: id });
      if (response.status === 200) {
        const { name } = response.data.data;
        setInitialValue({ name });
      }
    }

    api_call();

  }, []);



  const validationCheck = yup.object().shape({
    name: yup.string().trim().required("Name is required"),
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationCheck,
    onSubmit: async (values) => {
      try {
        values.id = id;
        const response = await updateIngredients(values);
        if (response.status === 200) {
          navigate('/admin/ingredients');
          toast(response.data.message);
        }
      } catch (error) {
        toast(error.response.data.message)
      }
    }
  });



  return (
    <>

      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Edit Ingredients</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active">Edit Ingredients</li>
              </ol>
            </div>
          </div>
        </div>
      </section>


      <section className="content">
        <FormikProvider value={formik} >
          <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Detail</h3>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                        <i className="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="inputName">Name</label>
                      <Field
                        type="text"
                        name="name"
                        placeholder="Cuisine Name"
                        className={`form-control`}
                      />


                      <div className="text-danger">
                        <ErrorMessage name="name" />
                      </div>
                    </div>


                  </div>
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-12">
                <input type="submit" value="Update" className="btn btn-success " />
              </div>
            </div>

          </Form>
        </FormikProvider>
      </section>


    </>
  )
}

export default IngredientsEdit;
