import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Component/Header';
import Sidebar from '../Component/Sidebar';
import Footer from '../Component/Footer';

const DashboardLayout = () => {

    return (
        <>
            <Header />
            <Sidebar />
            <div className="content-wrapper">
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default DashboardLayout