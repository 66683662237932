import React from 'react'
import { MainContext } from './context/MyContext';
import AdminRoutes from './Routes/adminRoutes';
import LoginRoutes from './Routes/loginRoutes'
import './assets/dist/css/adminlte.min.css';
import './assets/plugins/fontawesome-free/css/all.min.css';

const App = () => {
  const { token } = React.useContext(MainContext);
  return (
    <>
      {token !== null && token !== undefined && token !== "" ? <AdminRoutes /> : <LoginRoutes />}
    </>
  )

}
export default App