import React, { useReducer } from 'react'
import { Link } from 'react-router-dom';

const Header = () => {
  const [open, toggle] = useReducer((s) => !s, false);

  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <span style={{
              cursor: "pointer"
            }} className="nav-link" data-toggle="dropdown" onClick={toggle}>
              <i className="far fa-bell"></i>
              <span className="badge badge-warning navbar-badge">15</span>
            </span>
            <div className={`dropdown-menu dropdown-menu-lg dropdown-menu-right ${open == true ? "show" : ""}`}>
              <span className="dropdown-item dropdown-header">15 Notifications</span>
              <div className="dropdown-divider"></div>
              <Link to={"/"} className="dropdown-item">
                <i className="fas fa-envelope mr-2"></i> 4 new messages
                <span className="float-right text-muted text-sm">3 mins</span>
              </Link>
              <div className="dropdown-divider"></div>
              <Link to={"/"} className="dropdown-item">
                <i className="fas fa-users mr-2"></i> 8 friend requests
                <span className="float-right text-muted text-sm">12 hours</span>
              </Link>
              <div className="dropdown-divider"></div>
              <Link to={"/"} className="dropdown-item">
                <i className="fas fa-file mr-2"></i> 3 new reports
                <span className="float-right text-muted text-sm">2 days</span>
              </Link>
              <div className="dropdown-divider"></div>
              <Link to={"/notification"} className="dropdown-item dropdown-footer">See All Notifications</Link>
            </div>
          </li>
          <li className="nav-item dropdown">
          </li>
        </ul>
      </nav>
    </>
  )
}

export default Header;