import React, { useEffect, useState } from 'react';
import { FormikProvider, Field, Form, Formik, ErrorMessage, useFormik } from 'formik'
import * as yup from "yup";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { getOneCoupon, updateCoupon } from '../../Services/service';
import { toast } from 'react-toastify';
import { useParams,useNavigate } from 'react-router-dom';

//"admin", "vendor", "customer", "subAdmin"
const CouponAdd = () => {

    const navigate = useNavigate();
    //{ email, phone, couponCode, password, confirm_password } 
    const { id } = useParams();
    const [initialValues, setInitialValue] = useState({
        name: "",
        coupon_code: "",
        discount: "",
        start_date: "",
        end_date: "",
      
    });



    useEffect(() => {

        const api_call = async () => {
            const response = await getOneCoupon({ id: id });
            if (response.status === 200) {
                const {
                    name,
                    coupon_code,
                    discount,
                    end_date,
                    start_date
                } = response.data.data;
                setInitialValue({
                    name,
                    coupon_code,
                    discount,
                    end_date: dateFormat(end_date),
                    start_date: dateFormat(start_date)
                })
            }
        }

        api_call();

    }, []);
    //2023-06-23
    const dateFormat = (timestamp) => {
        const year = timestamp.slice(0, 4);
        const month = timestamp.slice(5, 7);
        const day = timestamp.slice(8, 10);

        // combine the year, month, and day into the desired format
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
    }


    const validationCheck = yup.object().shape({
        name: yup.string().min(3, "Please enter atleast 3 character").required("Coupon name is required"),
        coupon_code: yup.string().min(3, "Please enter atleast 3 character").required("Coupon code is required"),
        discount: yup.string().required("Coupon Discount is required") .matches(/^\d+$/, "Enter valid Discount Code")  .test("maxTwoDigits", "Discount cant more than 100", (number) => Number(number) <= 100),
        start_date: yup.string().required("Coupon Start Date is required"),
        end_date: yup.string().required("Coupon End Date is required"),
    })

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: validationCheck,
        onSubmit: async (values) => {
            try {
                const response = await updateCoupon({ id: id, ...values });
                if (response.status === 200) {
                    navigate('/admin/coupon');
                    toast(response.data.message);
                }
            } catch (error) {
                toast.error(error.response.data.message)
            }
        }
    });

    const {
        values,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        handleChange,
        handleSubmit,
        setValues,
        getFieldProps,
        setFieldValue,
        setFieldError,
        setErrors,
      } = formik;


    return (
        <>

            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Edit Coupon</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Add User</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Detail</h3>

                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="inputName">Coupon Name</label>
                                            <Field
                                                type="text"
                                                name="name"
                                                placeholder="name"
                                                className={`form-control`}
                                                value={values.name?.replace(/\s\s+/g, " ")}

                                            />
                                            <div className="text-danger">
                                                <ErrorMessage name="name" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="inputName">Coupon Code</label>
                                            <Field
                                                type="text"
                                                name="coupon_code"
                                                placeholder="Coupon code"
                                                className={`form-control`}
                                                value={values.coupon_code?.replace(/\s\s+/g, " ")}

                                            />
                                            <div className="text-danger">
                                                <ErrorMessage name="coupon_code" />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="inputName">Discount</label>
                                            <Field
                                                type="text"
                                                name="discount"
                                                placeholder="Discount"
                                                className={`form-control`}
                                           

                                            />
                                            <div className="text-danger">
                                                <ErrorMessage name="discount" />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="inputName">Start Date</label>
                                            <Field
                                                type="date"
                                                name="start_date"
                                                placeholder="Coupon code"
                                                className={`form-control`}

                                            />
                                            <div className="text-danger">
                                                <ErrorMessage name="start_date" />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="inputName">End Date</label>
                                            <Field
                                                type="date"
                                                name="end_date"
                                                placeholder="end_date"
                                                className={`form-control`}
                                                onChange={(e) => {
                                                    const newEndDate = e.target.value;
                                                    if (new Date(values.start_date) <= new Date(newEndDate)) {
                                                      setFieldValue('end_date', newEndDate);
                                                    }
                                                  }}

                                            />
                                            <div className="text-danger">
                                                <ErrorMessage name="end_date" />
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input type="submit" value="Edit Coupon" className="btn btn-success " />
                            </div>
                        </div>

                    </Form>
                </FormikProvider>
            </section>


        </>)
}
export default CouponAdd;