import React, { useEffect, useState } from 'react';
import { FormikProvider, Field, Form, Formik, ErrorMessage, useFormik } from 'formik'
import * as yup from "yup";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { addCoupon, getOneCoupon } from '../../Services/service';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import moment from "moment";


const CouponAdd = () => {
    const { id } = useParams();
    const [coupon, setCoupon] = useState({});
    useEffect(() => {
        const api_call = async () => {
            const response = await getOneCoupon({ id: id });
            if (response.status === 200) {
                console.log(response.data)
                const userData = response.data.data;
                setCoupon(userData);
            }
        }
        api_call();
    }, []);


    const dateFormat = (dateString) => {
        const dateObject = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-US', options);
        return formattedDate
    }

    return (
        <>

            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Add Coupon</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Add User</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">

                <div className="row">
                    <div className="col-md-6">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Detail</h3>

                                <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                        <i className="fas fa-minus"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="inputName">Coupon Name</label>
                                    <div>{coupon.name}</div>

                                </div>
                                <div className="form-group">
                                    <label htmlFor="inputName">Coupon Code</label>
                                    <div>{coupon.coupon_code}</div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="inputName">Start Date</label>
                                    <div> { moment(coupon.start_date).format('DD MMM YYYY')}</div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="inputName">End Date</label>
                                    <div> { moment(coupon.end_date).format('DD MMM YYYY')}</div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>


            </section>


        </>)
}
export default CouponAdd;