import axios from "axios"
import { loginUrl } from "../constant";

let token = '';



const config = () => {
    token = localStorage.getItem('token');
    return { headers: { "Authorization": `Bearer ${token}` } }
}

const form_data_config = () => {
    token = localStorage.getItem('token');
    return { headers: { "Content-Type": "multipart/form-data", "Authorization": `Bearer ${token}` } }
}





const login = async (values) => {
    return axios.post(`${loginUrl}/login`, values);
}
const forgetPassword = async (values) => {
    return axios.post(`${loginUrl}/forgetpassword`, values)
}
const otpVerify = async (values) => {
    return axios.post(`${loginUrl}/otp`, values)
}
const resetPassword = async (values) => {
    return axios.post(`${loginUrl}/resetpassword`, values)
}


//--- CMS ---//
const getAllCms = async () => {
    return axios.get(`${loginUrl}/getallcms`, config())
}

const getCms = async (values) => {
    return axios.post(`${loginUrl}/getcms`, values, config())
}

const updateCms = async (values) => {
    return axios.post(`${loginUrl}/updatecms`, values, config())
}

//--- FAQ ---//
const addFaq = async (values) => {
    return axios.post(`${loginUrl}/addfaq`, values, config())
}

const getFaq = async (values) => {
    return axios.post(`${loginUrl}/getfaq`, values, config())
}

const deleteFaq = async (values) => {
    return axios.post(`${loginUrl}/deletefaq`, values, config())
}

const updateFaq = async (values) => {
    return axios.post(`${loginUrl}/updatefaq`, values, config())
}


const getAllFaq = async () => {
    return axios.get(`${loginUrl}/allFaq`, config())
}


const getAllTutorial = async (values) => {
    return axios.post(`${loginUrl}/getAllTutorial`, values, config())
}

const getTutorial = async (values) => {
    return axios.post(`${loginUrl}/getTutorial`, values, config())
}

const updateTutorial = async (values) => {
    return axios.post(`${loginUrl}/updateTutorial`, values, config())
}

const addTutorial = async (values) => {
    return axios.post(`${loginUrl}/addTutorial`, values, config())
}

const deleteTutorial = async (values) => {
    return axios.post(`${loginUrl}/deleteTutorial`, values, config())
}



//--- Users ---//

const getAllUsers = async (values) => {
    return axios.post(`${loginUrl}/getAllUser`, values, config())
}
const getUser = async (values) => {
    return axios.post(`${loginUrl}/getUser`, values, config())
}
const updateUser = async (values) => {
    return axios.post(`${loginUrl}/updateUser`, values, config())
}
const addUser = async (values) => {
    return axios.post(`${loginUrl}/addUser`, values, config())
}
const changeStatus = async (values) => {
    return axios.post(`${loginUrl}/changeStatus`, values, config())
}

const changeDishStatus = async (values) => {
    return axios.post(`${loginUrl}/changeDishStatus`, values, config())
}




const getDishList = async (values) => {
    return axios.post(`${loginUrl}/getVendorDishes`, values, config())
}

const getDish = async (values) => {
    return axios.post(`${loginUrl}/getDisheDetails`, values, config())
}



const getAllDish = async (values) => {
    return axios.post(`${loginUrl}/getAllDishes`, values, config())
}

const getAllCuisine = async (values) => {
    return axios.post(`${loginUrl}/getAllCuisine`,values, config())
}
const getCuisine = async (values) => {
    return axios.post(`${loginUrl}/getCuisine`, values, config())
}

const updateCuisine = async (values) => {
    return axios.post(`${loginUrl}/updateCuisine`, values, config())
}

const addCuisine = async (values) => {
    return axios.post(`${loginUrl}/addCuisine`, values, config())
}


const deleteCuisine = async (values) => {
    return axios.post(`${loginUrl}/deleteCuisine`, values, config())
}


const getAllIngredients = async (values) => {
    return axios.post(`${loginUrl}/getAllIngredients`,values, config())
}
const getIngredients = async (values) => {
    return axios.post(`${loginUrl}/getIngredients`, values, config())
}

const updateIngredients = async (values) => {
    return axios.post(`${loginUrl}/updateIngredients`, values, config())
}

const addIngredients = async (values) => {
    return axios.post(`${loginUrl}/addIngredients`, values, config())
}


const deleteIngredients = async (values) => {
    return axios.post(`${loginUrl}/deleteIngredients`, values, config())
}




const getSetList = async (values) => {
    return axios.post(`${loginUrl}/getVendorSets`, values, config())
}




const getSiteSetting = async () => {
    return axios.get(`${loginUrl}/getAllHomeContent`, config())
}

const updateSiteSetting = async (values) => {
    return axios.post(`${loginUrl}/updateHomeContent`, values, config())
}



//--- Testimonials ---//
const getAllTestimonials = async () => {
    return axios.get(`${loginUrl}/getAllTestimonials`, config())
}


const deleteTestimonials = async (values) => {
    return axios.post(`${loginUrl}/deleteTestimonials`, values, config())
}

const updateTestimonials = async (values) => {
    return axios.post(`${loginUrl}/updateTestimonials`, values, form_data_config())
}

const getTestimonial = async (values) => {
    return axios.post(`${loginUrl}/getTestimonials`, values, config())
}

const addTestimonials = async (values) => {
    return axios.post(`${loginUrl}/addTestimonials`, values, form_data_config())
}




//-- Coupon --//

const addCoupon = async (values) => {
    return axios.post(`${loginUrl}/addCoupon`, values, config())
}

const updateCoupon = async (values) => {
    return axios.post(`${loginUrl}/updateCoupon`, values, config())
}

const deleteCoupon = async (values) => {
    return axios.post(`${loginUrl}/deleteCoupon`, values, config())
}

const getAllCoupon = async (values) => {
    return axios.post(`${loginUrl}/getAllCoupon`, values, config())
}

const getOneCoupon = async (values) => {
    return axios.post(`${loginUrl}/getOneCoupon`, values, config())
}


//-- Banner --//
const getAllBanner = async (values) => {
    return axios.get(`${loginUrl}/findAllBanner`, form_data_config())
}


const getBanner = async (values) => {
    return axios.post(`${loginUrl}/findOneBanner`, values, config())
}

const addBanner = async (values) => {
    return axios.post(`${loginUrl}/addBanner`, values, form_data_config())
}

const updateBanner = async (values) => {
    return axios.post(`${loginUrl}/updateBanner`, values, form_data_config())
}
const deleteBanner = async (values) => {
    return axios.post(`${loginUrl}/deleteBanner`, values, config())
}


//--Email Template --//

const getAllEmailTemplate = async (values) => {
    return axios.get(`${loginUrl}/findAllEmailTemplate`, form_data_config())
}


const getEmailTemplate = async (values) => {
    return axios.post(`${loginUrl}/findOneEmailTemplate`, values, config())
}

const addEmailTemplate = async (values) => {
    return axios.post(`${loginUrl}/addEmailTemplate`, values, form_data_config())
}

const updateEmailTemplate = async (values) => {
    return axios.post(`${loginUrl}/updateEmailTemplate`, values, form_data_config())
}
const deleteEmailTemplate = async (values) => {
    return axios.post(`${loginUrl}/deleteEmailTemplate`, values, config())
}


//--Referral --//


const getAllReferral = async (values) => {
    return axios.get(`${loginUrl}/findAllReferral`, config())
}


const getReferral = async (values) => {
    return axios.post(`${loginUrl}/findOneReferral`, values, config())
}

const addReferral = async (values) => {
    return axios.post(`${loginUrl}/addReferral`, values, config())
}

const updateReferral = async (values) => {
    return axios.post(`${loginUrl}/updateReferral`, values, config())
}
const deleteReferral = async (values) => {
    return axios.post(`${loginUrl}/deleteReferral`, values, config())
}

const deleteDish = async (values) => {
    return axios.post(`${loginUrl}/deleteDish`, values, config())
}






const deleteSet = async (values) => {
    return axios.post(`${loginUrl}/deleteSet`, values, config())
}

export {
    login,
    forgetPassword,
    otpVerify,
    resetPassword,
    getAllCms,
    updateCms,
    getAllFaq,
    getCms,
    addFaq,
    getFaq,
    updateFaq,
    deleteFaq,

    //-- User --//
    addUser,
    getAllUsers,
    getUser,
    updateUser,
    changeStatus,
    getDishList,
    getSetList,
    deleteDish,
    deleteSet,
    getDish,

    //-- Coupon --//
    addCoupon,
    deleteCoupon,
    getAllCoupon,
    getOneCoupon,
    updateCoupon,

    //-- Banner --//
    deleteBanner,
    updateBanner,
    getAllBanner,
    getBanner,
    addBanner,
    getAllDish,
    changeDishStatus,


    //--  Email Template --//
    deleteEmailTemplate,
    updateEmailTemplate,
    getAllEmailTemplate,
    getEmailTemplate,
    addEmailTemplate,

    //-- Referral --//
    getAllReferral,
    getReferral,
    addReferral,
    updateReferral,
    deleteReferral,


    //-- SETTINGS --//
    getSiteSetting,
    updateSiteSetting,
    getAllTestimonials,
    deleteTestimonials,
    updateTestimonials,
    getTestimonial,
    addTestimonials,


    getAllCuisine,
    deleteCuisine,
    getCuisine,
    updateCuisine,
    addCuisine,

    getAllIngredients,
    deleteIngredients,
    getIngredients,
    updateIngredients,
    addIngredients,

    getAllTutorial,
    getTutorial,
    updateTutorial,
    addTutorial,
    deleteTutorial
}