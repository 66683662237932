import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllTestimonials, deleteTestimonials } from '../../Services/service';
import parse from 'html-react-parser';
import { base } from '../../constant';

const TestimonialsList = () => {

  const [List, setList] = useState([]);

  useEffect(() => {

    async function getList() {
      const response = await getAllTestimonials();
      if (response.status === 200) {
        setList(response.data.data);
        return null;
      }
    }

    getList()

  }, []);


  const deleteHandler = async (id) => {
    var result = window.confirm("Want to delete?");
    if (result) {
      const response = await deleteTestimonials({ id: id });
      if (response.status === 200) {
        setList(List.filter(record => record._id !== id));
      }
    }
  }


  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Testimonials Managment</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active">Testimonials Managment</li>
              </ol>
            </div>
          </div>
        </div>
      </section>


      <section className="content">


        <div className="card-header">
          <div className="card-tools">
            <Link to='./add' className="btn btn-info btn-sm float-right" >
              Add Testimonial
            </Link>
          </div>
        </div>


        <div className="card">

          <div className="card-body p-0">
            <table className="table table-striped projects">
              <thead>
                <tr>
                  <th >
                    #
                  </th>
                  <th style={{ width: "20%" }}>
                    Image
                  </th>
                  <th style={{ width: "20%" }}>
                    Name
                  </th>


                  <th className="text-center" style={{ width: "30%" }}>
                    Comment
                  </th>
                  <th >
                  </th>
                </tr>
              </thead>
              <tbody>



                {List.map((item, index) => {

                  return <tr key={index + 1}>
                    <td>
                      #{index + 1}
                    </td>
                    <td>
                      <a>
                        <img src={base + "/" + item.image} style={{ width: 100 }} />
                      </a>

                    </td>
                    <td>
                      <a>
                        {item.name}
                      </a>

                    </td>



                    <td className="project-state">
                      <a>
                        {parse(item.comment)}
                      </a>
                    </td>
                    <td className="project-actions text-right">

                      <Link to={`./edit/${item._id}`} className="btn btn-info btn-sm" >
                        <i className="fas fa-pencil-alt">
                        </i>
                        Edit
                      </Link>
                      <a onClick={() => {
                        deleteHandler(item._id)
                      }} className="btn btn-danger btn-sm" >
                        <i className="fas fa-trash">
                        </i>
                        Delete
                      </a>
                    </td>
                  </tr>
                })}

              </tbody>
            </table>
          </div>
        </div>
      </section>

    </>
  )
}

export default TestimonialsList;